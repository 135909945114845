import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { LanguageTranslateService } from 'src/app/services/language-translate.service';
import * as Global from 'src/app/shared/globals';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { DatePipe } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { PurchaseCreditDialogComponent } from './purchase-credit-dialog/purchase-credit-dialog.component';
import { RazorpayPaymentService } from 'src/app/services/razorpay-payment.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CreditPurchaseHistoryComponent } from './credit-purchase-history/credit-purchase-history.component';
import PaginationOptions from 'src/app/models/PaginationOptions';
import { AppComponent } from 'src/app/app.component';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit, OnDestroy {
  Global = Global;
  upcomingBirthdaysList: any[] = [];
  upcomingWorkAnniversaryList: any[] = [];
  paginationOptionsExpiring: PaginationOptions = Global.resetPaginationOption();
  latestAnnouncementData: any[] = [];
  dashboardCTCData: any[] = [];
  noticeData: any[] = [];
  lastTenFnf: any[] = [];
  empLeaveList: any[] = [];
  filterTimeChartMonths: any[] = [];
  ctcLoading: boolean = false;
  currentLanguage$?: Subscription;
  translations: any;
  todayDate: Date;
  todayTomoClass: any;
  newJoinedEmployees: number = 0;
  leaveList: any;
  selectedMonth: string = '';
  today: Date = new Date();
  totalEmployees: any;
  creditBalance?: number;
  leaveApplication: number = 0;
  company_logo: string = '';
  curr_month: any;
  dateNow: any;
  curr_year: any;
  isLoading!:boolean;
  classArray:any[] = ['border-yello', 'border-green', 'border-red'];

  filterForm: FormGroup = new FormGroup({
    ctc_date_filter: new FormControl('', Validators.required),
  });
  lang!: string;

  constructor(
    private langService: LanguageTranslateService,
    private dashboardService: DashboardService,
    private authService: AuthService,
    private datePipe: DatePipe,
    private toastr: ToastrService,
    private router: Router,
    private dialog: MatDialog,
    private cdr:ChangeDetectorRef,
    private paymentService: RazorpayPaymentService,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    private appComponent: AppComponent
  ) {
    this.upcomingBirthdaysList = [];
    this.filterTimeChartMonths = [];
    this.todayDate = new Date();
    authService.com_logo$.subscribe((res: any) => {
      this.company_logo = res;
    });

    // this.dateNow = new Date();
    this.curr_month = this.today.getMonth().toString();

    this.curr_year = this.todayDate.getFullYear();
    // this.selectedMonth = Global.getMonthRange[parseInt(this.attendance_month)];
    this.getDashboardCTCData();
  }
  public barChartPlugins = [];
  public barChartLegend = true;
  financialYearOptions: any[] = [];

  ngOnInit(): void {
    this.currentLanguage$ = this.langService.currentLanguage$.subscribe(
      async () => {
        return new Promise<boolean>(async (resolve, reject) => {
          await this.langService
            .loadTranslations('home', 'HomeComponent')
            ?.then((res: any) => {
              this.translations = res;
              resolve(true);
            })
            .catch((err: any) => {
              console.error(`Failed to load translations`, err);
              reject(false);
            });
        });
      }
    );
    this.activatedRoute.paramMap.subscribe((params: any) => {
      this.isLoading = true;
      this.spinner.show();
      this.lang = params.get('lang');
      // this._adapter.setLocale(this.lang);
      setTimeout(() => {
        this.isLoading = false;
        this.spinner.hide();
      }, 1000);
    });

    let years = [
      new Date().getFullYear() - 2,
      new Date().getFullYear() - 1,
      new Date().getFullYear(),
      new Date().getFullYear() + 1,
    ];
    for (let i = 0; i < years.length - 1; i++) {
      let combinedYear = {
        label: `${years[i]}-${years[i + 1].toString().slice(-2)}`,
        value: `${years[i]}-${years[i + 1].toString()}`,
      };
      this.financialYearOptions.push(combinedYear);
    }

    this.filterForm.patchValue({
      ctc_date_filter: this.financialYearOptions[1]?.value,
    });
    this.getNewJoinedEmployees();
    this.getLeaveList();
    this.getUpcomingBirthdays();
    this.getUpcomingWork();
    this.getTotalEmployee();
    this.latestAnnouncement();
    // this.latestNotice();
    this.getNoticeExpiring()
  }
  public barChartType: ChartType = 'bar';
  public barChartData2: ChartData<any> = {
    labels: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],

    datasets: [
      {
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        label: 'Budget',
        backgroundColor: '#51F1E6',
        borderColor: '#51F1E6',
        pointBackgroundColor: '#fff',
        pointBorderColor: '#51F1E6',
        order: 1,
      },
      {
        data: [0,0,0,0,0,0,0,0,0,0,0,0],
        label: 'Actual Processed Amount ',
        backgroundColor: '#07515A',
        borderColor: '#07515A',
        pointBackgroundColor: '#fff',
        pointBorderColor: '#07515A',
        order: 2,
      },
      // {
      //   data: [0, 0, 0, 6, 0, 0, 0, 0, 0, 0, 0, 0],
      //   label: 'Active Employee',
      //   backgroundColor: '#51F1E6',
      //   borderColor: '#51F1E6',
      //   order: 0,
      //   type: 'line',
      //   // pointBackgroundColor: '#fff',
      //   // pointBorderColor: '#51F1E6',
      // },
    ],
  };

  public barChartOptions2: ChartConfiguration['options'] = {
    maintainAspectRatio: false,
    // responsive: false,
    aspectRatio: 1.5,
    // animation: false,
    scales:{
      y: {
        beginAtZero: true, // Ensure the Y-axis starts at 0
      }
    },
    plugins: {
      legend: {
        display: true,
      },
    },
  };


  public barChartOptions: ChartConfiguration<'line'>['options'] = {
    responsive: false,
    aspectRatio: 1.5,
    animation: false,

    plugins: {
      legend: {
        display: false,
      },
    },
  };
  async getDashboardCTCData() {
    try {
      this.barChartData2.datasets[0].data = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      ];
      this.barChartData2.datasets[1].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      this.ctcLoading = true;
      let keys = this.filterForm.value.ctc_date_filter.split('-');

      let payload = {
        ctc_date_filter: JSON.stringify({
          from: keys[0],
          to: keys[1],
        }),

        financial_year_end: JSON.parse(
          localStorage.getItem('hrmlix-uae-companyuser-details') as any
        ).preference_settings?.financial_year_end,
      };
      let res = await this.dashboardService.getDashboardCTCData(payload);
      if (res.status !== 'success') throw res;
      this.dashboardCTCData = res.dashboard_data;
      let months = this.getCurrFinancialYearMonths();
      let items: any = [];
      console.log(months,'momnnedwr');
      
      months.forEach((month) => {
        items.push({
          month: month,
          logs: res.dashboard_data.companies_monthly_data_logs.find(
            (challan: any) => +challan._id == month?.index
          ),
          report: res.dashboard_data.employee_monthly_reports.find(
            (emp: any) => +emp._id == month?.index
          ),
        });
      });
      this.barChartData2.datasets[0].data = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      ];
      this.barChartData2.datasets[1].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

      if (items?.length) {
        this.barChartData2.datasets[0].data = [];
        this.barChartData2.datasets[1].data = [];
        this.barChartData2.labels = [];

        for (let i = 0; i < items.length; i++) {
          const item = items[i];
          this.barChartData2?.labels?.push(item?.month?.sf);
          this.barChartData2?.datasets[0]?.data?.push(item?.logs?.total_budget ?? 0);
          this.barChartData2?.datasets[1]?.data?.push(item?.report?.total_gross_salary ?? 0);
        }
        
      }
      this.ctcLoading = false;
    } catch (err: any) {
      // this.toastr.error(err.message || err);
    }
  }
  getCurrFinancialYearMonths() {
    let financialMonths = [];
    let financial_year_end = new Date(
      JSON.parse(
        localStorage.getItem('hrmlix-uae-companyuser-details') as any
      ).preference_settings?.financial_year_end
    );

    for (let i = 0; i < 12; i++) {
      financialMonths.push(
        Global.monthMaster.find(
          (month) =>
            month.index ==
            new Date(
              financial_year_end.getFullYear(),
              financial_year_end.getMonth() + i,
              +1
            ).getMonth()
        )
      );
    }
    return financialMonths;
  }
  getEmpDate(date: any) {
    let empDate = new Date(date);
    const millisecondsInDay = 86400000;
    const tomorrowDate = new Date();
    tomorrowDate.setTime(tomorrowDate.getTime() + millisecondsInDay);
    if (
      empDate.getDate() === this.todayDate.getDate() &&
      empDate.getMonth() === this.todayDate.getMonth()
    ) {
      return (this.todayTomoClass = 'Today');
    } else if (
      empDate.getDate() === tomorrowDate.getDate() &&
      empDate.getMonth() === tomorrowDate.getMonth()
    ) {
      return (this.todayTomoClass = 'Tomorrow');
    } else {
      this.todayTomoClass = '';
      return Global.formatedDate(date);
    }
  }
  formatDate(dateString: string): { day: string, month: string } {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short' } as const;
    const formattedDate = date.toLocaleDateString('en-US', options);
    const [month, day] = formattedDate.split(' ');
    return { day, month };
  }
  getEmmApprDate(date: any) {
    let empDate = new Date(date);
    const millisecondsInDay = 86400000;
    const tomorrowDate = new Date();
    tomorrowDate.setTime(tomorrowDate.getTime() + millisecondsInDay);
    if (
      empDate.getDate() === this.todayDate.getDate() &&
      empDate.getMonth() === this.todayDate.getMonth()
    ) {
      return (this.todayTomoClass = 'Today');
    } else if (
      empDate.getDate() === tomorrowDate.getDate() &&
      empDate.getMonth() === tomorrowDate.getMonth()
    ) {
      return (this.todayTomoClass = 'Tomorrow');
    } else {
      this.todayTomoClass = '';
      return `${Global.getDateMonth(date)} ${this.curr_year}`;
    }
  }
  routeToannounce() {
    this.lang = this.langService.getLangLocalStorage() ?? 'en';
    this.router.navigate([this.lang, 'announcement-list']);
  }
  getNewJoinedEmployees() {
    return new Promise((resolve, reject) => {
      this.dashboardService
        .newJoinedEmployees({})
        .then((res: any) => {
          this.newJoinedEmployees = res?.data;
          resolve(true);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
  }
  getLeaveList() {
    const date = new Date();
    return new Promise((resolve, reject) => {
      this.dashboardService
        .getLeaveList({
          pageno: 1,
          status: 'approved',
          perpage: 10,
          year: date.getFullYear(),
        })
        .then((res: any) => {
          this.empLeaveList = res?.data?.docs;
          resolve(true);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
  }
  routeToNotice() {
    this.lang = this.langService.getLangLocalStorage() ?? 'en';
    this.router.navigate([this.lang, 'notice']);
  }
  routeToAppraisal() {
    this.lang = this.langService.getLangLocalStorage() ?? 'en';
    this.router.navigate([this.lang, 'appraisal', 'apply-appraisal']);
  }
  navigateToEmployees(filter:any) {
    const queryParams: NavigationExtras = {
      queryParams: { filter: filter }
    };

    this.router.navigate([this.lang, 'employees', 'employee-listing'], queryParams);
  }
  routeToPage(module:string, page:string){
   this.router.navigate([this.lang, module, page ])
  }
  getUpcomingBirthdays() {
    const date = new Date();
    return new Promise((resolve, reject) => {
      this.dashboardService
        .getUpcomingBirthdays({page:1,perpage:10})
        .then((res: any) => {
          this.upcomingBirthdaysList = res?.data?.docs;
          resolve(true);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
  }
  getUpcomingWork() {
    const date = new Date();
    return new Promise((resolve, reject) => {
      this.dashboardService
        .getUpcomingWorkAnni({page:1, perpage:10})
        .then((res: any) => {
          this.upcomingWorkAnniversaryList = res?.data?.docs;
          resolve(true);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
  }

  getTotalEmployee() {
    const date = new Date();
    return new Promise((resolve, reject) => {
      this.dashboardService
        .getTotalEmployes({})
        .then((res: any) => {
          this.totalEmployees = res?.dashboard_data;
          this.cdr.detectChanges();
          this.creditBalance = res?.dashboard_data?.credit_stat;
          
          resolve(true);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
  }

  latestAnnouncement() {
    const date = new Date();
    return new Promise((resolve, reject) => {
      this.dashboardService
        .latestAnnouncement({page:1,perpage:5})
        .then((res: any) => {
          this.latestAnnouncementData = res?.data?.docs ?? [];
          resolve(true);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
  }
  getNoticeExpiring({page = <any>null,
    loading = <boolean>true,
    filter = <any>null,
  } = {}){
    const date = new Date();
    return new Promise((resolve,reject)=>{
      this.dashboardService.getNotice(
        { 
          "pageno":1,
          "perpage":5,
          "type":"expiring"
        }).then((res:any)=>{
          if (res?.status === 'success') {
            const data:any = res?.data ?? [];
            this.noticeData = res?.data?.docs ?? [];  
            this.paginationOptionsExpiring = {
              page: data?.page,
              limit: data?.limit,
              hasNextPage: data?.hasNextPage,
              hasPrevPage: data?.hasPrevPage,
              nextPage: data?.nextPage,
              prevPage: data?.prevPage,
              totalDocs: data?.totalDocs,
              totalPages: data?.totalPages,
              pagingCounter: data?.pagingCounter
            }     
          }
            
          resolve(true);
          
      }).catch((err:any)=>{
        reject(err)
      })
    })
  }
  latestNotice() {
    const date = new Date();
    return new Promise((resolve, reject) => {
      this.dashboardService
        .latestNotice({"type":"expiring", "perpage":10})
        .then((res: any) => {
          this.noticeData = res?.data?.docs ?? [];
          
          resolve(true);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
  }

  async fetchCreditSettings() {
    try {
      this.spinner.show();
      const res = await this.paymentService.getCreditSettingsValue();
      if (
        res.settings_value?.credit_amount &&
        res.settings_value?.credit_value
      ) {
        const creditAmountPerToken =
          parseFloat(res.settings_value?.credit_amount || 0) /
          parseFloat(res.settings_value?.credit_value || 0);
        const vatPercentage = parseFloat(res.settings_value?.vat_amount || 0);
        this.spinner.hide();
        this.openPurchaseCreditDialog({
          creditAmountPerToken,
          vatPercentage: vatPercentage,
        });
      }
    } catch (err: any) {
      this.spinner.hide();
      this.toastr.error(err?.message || err || 'Something went wrong');
    }
  }

  openPurchaseCreditDialog(data: any) {
 const dialogRef = this.dialog.open(PurchaseCreditDialogComponent, {
      data,
      minWidth: '450px',
      width: 'min-content',
      height: 'fit-content',
      maxHeight: '90vh',
      hasBackdrop: true,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((res:any)=>{
      if (res === true) {
        this.getTotalEmployee();
      }
      // this.appComponent.fetchCompanyUserAuthDetails();
      
    })
  }

  openPaymentHistoryDialog() {
  const dialogRef = this.dialog.open(CreditPurchaseHistoryComponent, {
      data: {},
      minWidth: '450px',
      width: 'min-content',
      height: 'fit-content',
      maxHeight: '90vh',
      hasBackdrop: true,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((res:any)=>{
      if (res === true) {
        this.getTotalEmployee();
      }
    })
  }

  routeToanncDetails(id:any){
    this.router.navigate([this.lang,'announcement-detail',id])
  }
  routeTonoticeDetails(id:any, document_id:any){
    
    this.router.navigate([this.lang,'employees',id,'edit','documents','user',document_id])
  }
  ngOnDestroy(): void {
    this.currentLanguage$?.unsubscribe();
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as Global from 'src/app/shared/globals';

@Component({
  selector: 'app-custom-table-option',
  templateUrl: './custom-table-option.component.html',
  styleUrls: ['./custom-table-option.component.css']
})
export class CustomTableOptionComponent {
  TableOptionForm: UntypedFormGroup;
  Global = Global;

  @Input() lengthChange: boolean = true;
  @Input() searchable: boolean = true;
  @Output() onFilterChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public formBuilder: UntypedFormBuilder,
  ) {
    this.TableOptionForm = formBuilder.group({
      length: [Global.DataTableLength, Validators.compose([])],
      searchkey: [null, Validators.compose([])],
    });
  }

  ngOnInit(): void {
  }

  filterChanged() {
    console.log('caling....filter');
    
    // console.log('this.TableOptionForm.value : ', this.TableOptionForm.value);
    this.onFilterChanged.emit(this.TableOptionForm.value)
  }

}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import PaginationOptions from 'src/app/models/PaginationOptions';
import { LanguageTranslateService } from 'src/app/services/language-translate.service';

import * as Global from 'src/app/shared/globals';

@Component({
  selector: 'app-custom-pagination',
  templateUrl: './custom-pagination.component.html',
  styleUrls: ['./custom-pagination.component.css']
})
export class CustomPaginationComponent {

  Global = Global;
  @Input() rows: any[] = [];
  currentLanguage$?:Subscription;
  translations:any;
  @Input() paginationOptions: PaginationOptions = {
      hasNextPage: false,
      hasPrevPage: false,
      limit: Global.DataTableLength,
      nextPage: null,
      page: 1,
      pagingCounter: 1,
      prevPage: null,
      totalDocs: 0,
      totalPages: 1,
  };

  @Output() onPageClicked: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private langService:LanguageTranslateService
  ) { }
  lang:any = 'en'
  ngOnInit(): void {
    console.log(this.rows)
    this.currentLanguage$ = this.langService.currentLanguage$.subscribe(async () => {
this.lang = this.langService.getLangLocalStorage();
      return new Promise<boolean>(async (resolve, reject) => {
        await this.langService.loadTranslations('layout', 'CustomPaginationComponent')?.then((res: any) => {
          this.translations = res;          
          resolve(true);
        }).catch((err: any) => {
          console.error(`Failed to load translations`, err);
          reject(false);
        });
      })
    });
  }    
 
}

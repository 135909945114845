<div class="app-content leave-cms">
    <app-menu-sidebar></app-menu-sidebar>

    <div class="app-header topfinance-nav">
        <ul>
            <li class="static-text" [class.active]="isActive('document')" (click)="routerChange('document')"><h3></h3>{{translations?.vault}}</li>
            
        </ul>
        
        <app-language></app-language>
        <app-profile></app-profile>
    </div>

  
    <router-outlet></router-outlet>

</div>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LanguageTranslateService } from 'src/app/services/language-translate.service';

@Component({
  selector: 'app-approval',
  templateUrl: './approval.component.html',
  styleUrls: ['./approval.component.css']
})
export class ApprovalComponent implements OnInit {
  lang:any="en"
  currentLanguage$?: Subscription;
  translations: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private langService: LanguageTranslateService,
  ) {
    this.route.paramMap.subscribe((params: any) => {
      this.lang = params.get('lang');
      // console.log(this.lang,'lanefrhg');

    });
  }

  ngOnInit(): void {
    this.currentLanguage$ = this.langService.currentLanguage$.subscribe(async () => {
    this.lang = this.langService.getLangLocalStorage();
      return new Promise<boolean>(async (resolve, reject) => {
        await this.langService.loadTranslations('approval', 'ApprovalComponent')?.then((res: any) => {
          this.translations = res;
          resolve(true);
        }).catch((err: any) => {
          console.error(`Failed to load translations`, err);
          reject(false);
        });
      })
    });
  }
  ngOnDestroy() {
    this.currentLanguage$?.unsubscribe();
  }
  isActive(routeString: string): boolean {
    if (this.router.url.includes(routeString)) {
      return true;
    }
    return false;
  }
  routerChange(routeString: string) {
    this.router.navigate([this.lang, 'approval', routeString]);
  }
}

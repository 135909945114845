


<div class="info-fluid height100 m-4">
    <div class="container mb-4">
      <div class="hrmlogo">
        <img class="static-text" src="assets/images/logo.png" alt="logo">
     </div>
      <div class="taxformmain" *ngIf="isLinkValid && !isFormSubmit && this.empDetails?.offer_letter_sent == 'yes'">
          <div class="content" >
            <div class="boxshodo borderredius-0">
              <div class="outborder">
                <div class="yelborder">
                    
                  <ol class="ps-0 mb-0">
                    <li class="d-flex justify-content-between">
                      <div  class="cursor-pointer" >
                      <ng-container *ngIf="company_logo; else noComlogo">
                          <div class="profileimg mb-2" >
                              <img [src]="company_logo"  alt="user">
                          </div>
                      </ng-container>
                      <ng-template #noComlogo >
                          <div class="profileimg mb-2">
                              <img src="assets/images/user.jpg"  alt="user">
                          </div>
                      </ng-template>
                      <p class="subhead mb-0">{{company_name}}</p>
                      <p>{{com_address}}</p>
                    </div>
                    <p class="subhead mb-0">{{date}}</p>
                  </li>
                      
                  <hr class="my-4">

                  <p>
                    <li><b>{{emp_name}}</b></li>
                    <li>{{emp_address}}</li>
                  </p>
              
                  <li><h4 class="text-center my-md-4">OFFER LETTER</h4></li>
                
                  <br>


                  <p>Dear <b>{{emp_name}}</b>,</p>
                  <li>We are pleased to offer you the position of <b> {{job_title}}</b> at <b>{{company_name}}</b>, as discussed during your interview.</li>
                    <li> We believe your skills and experience make you an ideal fit for our team and contribute to the company's growth.</li>
                
                    <br>
                    
                      <p><b>Below are the details of the offer: </b></p>
                      <ul class="jobdata">
                        <li><strong>Job Title</strong> : {{job_title}} </li>
                        <li><strong>Department</strong> : {{department}}</li>
                        <!-- <li>Reporting Manager: {{hr_name}}</li> -->
                        <li><strong>Expected Joining Date</strong> : {{expected_date}} </li>
                        <li><strong>Salary</strong> : {{salary}}&nbsp;  AED (Per Annum)</li>
                        <li  class="d-flex"><strong>Benefits </strong> :
                          <ul class="ben">
                            <ng-container *ngFor="let data of benefits">
                                <li> - {{data}}</li>
                            </ng-container>
                          </ul>
                        </li>
                      </ul>
                      <br>
                    
                 
                      <p>Please confirm your acceptance of this offer by signing and returning one copy of this letter to us by <b>{{deadline_date}} </b>. We also require a start date confirmation.
                      </p>
                      <li>If you have any questions or concerns, please do not hesitate to contact us.</li>
                      <li>Congratulations on your new role, and we look forward to welcoming you to {{company_name}}!</li>
                  
                    <br>
                    <li>
                      <p>Sincerely,</p>
    
                      <p class="subhead mb-0">{{hr_name}} </p>
                      <p class="mb-0">HR</p>
                      <p class="mb-0">{{company_name}}</p>
                    </li>

                    <br><hr><br>
                    <form [formGroup]="acceptanceForm">
                      <h5>Acceptance:</h5>
                      <p>I, <b>{{emp_name}}</b>, accept the offer of employment with <b>{{company_name}}</b> as outlined above. I confirm my start date as <b>{{expected_date}}</b>.</p>
                      
                      <h5 style="font-size: 16px; color: #007aff;">Choose either of the options to submit your signature.</h5>
                      
                      <p class="mb-2"><b>Signature:</b></p>
                    
                      <!-- Signature Pad Container -->
                      <div class="signature-container" style="position: relative;">
                        <!-- Signature Pad -->
                        <signature-pad 
                          [options]="signaturePadOptions" 
                          (onEndEvent)="drawComplete()"
                          #signaturePad>
                        </signature-pad>
                    
                        <!-- Overlay to disable the signature pad when isSignaturePadEnabled is false -->
                        <div 
                          *ngIf="!isSignaturePadEnabled" 
                          class="signature-overlay" 
                          style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: rgba(255,255,255,0.7);">
                        </div>
                    
                        <button style="cursor: pointer;" (click)="clearPad()" class="btn button-solide  gray-btn">Clear</button>
                      </div>
                    
                      <br>
                    
                      <!-- Hidden input for the signature field -->
                      <input type="text" formControlName="signature" class="form-control" hidden
                        [ngClass]="{'is-invalid': acceptanceForm.get('signature')?.hasError('required') && acceptanceForm.get('signature')?.touched}"
                        [disabled]="!isSignaturePadEnabled">
                    
                      <span class="invalid-feedback" *ngIf="acceptanceForm.get('signature')?.hasError('required') && acceptanceForm.get('signature')?.touched" style="font-size: 15px;">
                        Either Signature OR Signature Image Is required.
                      </span>
                    
                      <br>
                      <p class="mb-2"><b>Upload Signature Image: </b></p>
                        <input type="file" accept=".jpeg, .jpg, .png, .pdf" (change)="onSignatureImageChange($event)" class="form-control" 
                          [ngClass]="{'is-invalid': acceptanceForm.get('signatureImage')?.hasError('required') && acceptanceForm.get('signatureImage')?.touched}"
                          [disabled]="!isFileUploadEnabled">
                        <span class="invalid-feedback" *ngIf="acceptanceForm.get('signatureImage')?.hasError('required') && acceptanceForm.get('signatureImage')?.touched">
                          Signature image is required.
                        </span>
                      
                    </form>
                    
                <p class="mt-4">Date: {{date}}</p>
                  </ol>
                  </div>
                  
            </div>
         </div>
       </div>
    
       <div class="row  mt-2" >  
        <div class="col-12 col-sm-12">
          
          <button class="btn button-solide" (click)="acceptOffer(true, $event)">
            <span> ACCEPT</span>
          </button>&nbsp;&nbsp;
    
          <button class="btn button-solide gray-btn" (click)="acceptOffer(false, $event)" >
            <span>REJECT</span>
          </button>
        </div>
      </div>
    </div>
    
    </div>
    </div>
    <div *ngIf="!isLinkValid && !isLoading" class="text-center">
        <h2 class="mt-5 pt-5">This Invite link is Expired!</h2>
      </div>
      <div *ngIf="isFormSubmit == 'yes'" class="text-center">
        <h2 class="mt-5 pt-5">Thanks For Your Response! Will Get Back To You Soon!</h2>
      </div>
      <div *ngIf="isFormSubmit == 'no'" class="text-center">
        <h2 class="mt-5 pt-5">Your Deadline Date Has Expired.</h2>
      </div>
      <div *ngIf="isValid == 'inactive'" class="text-center">
        <h2 class="mt-5 pt-5">You Already Have Submitted Response!</h2>
      </div>
     
      <div *ngIf="this.empDetails?.offer_letter_sent == 'no'" class="text-center">
        <h2 class="mt-5 pt-5">The Requested Link Is Not Valid!</h2>
      </div>
    
    
    
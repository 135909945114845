<app-menu-sidebar></app-menu-sidebar>
<div class="app-content leave-cms">
    <div class="app-header topfinance-nav">
        <ul>
            <li [class.active]="isActive('salary')" class="static-text"><a (click)="routerChange('salary')">{{translations?.salary}}</a></li>
            <li [class.active]="isActive('incentive')" class="static-text"><a (click)="routerChange('incentive')">{{translations?.incentive}}</a></li>
            <li [class.active]="isActive('bonus')" class="static-text"><a (click)="routerChange('bonus')">{{translations?.bonus}}</a></li>
            <li [class.active]="isActive('advance')" class="static-text"><a (click)="routerChange('advance')">{{translations?.advance}}</a></li>
            <li [class.active]="isActive('over-time')" class="static-text"><a (click)="routerChange('over-time')">{{translations?.overtime}}</a></li>
            <li [class.active]="isActive('reimbursement')" class="static-text"><a (click)="routerChange('reimbursement')">{{translations?.reimbursement}}</a></li>
            <li [class.active]="isActive('extraearning')" class="static-text"><a (click)="routerChange('extraearning')">{{translations?.extraEarning}} </a></li>
            <li [class.active]="isActive('payment-instruction')" class="static-text"><a (click)="routerChange('payment-instruction')">Payment Instruction </a></li>
            <!-- <li [class.active]="isActive('over-time')" class="static-text"><a (click)="routerChange('over-time')">{{translations?.overtime}} </a></li> -->
        </ul>
        
        <app-language></app-language>
        <app-profile></app-profile>
    </div>
    <router-outlet></router-outlet>
</div>
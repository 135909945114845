
<app-menu-sidebar></app-menu-sidebar>
<div class="app-content leave-cms">
  <div class="app-header topfinance-nav ">
    <ul>
      <li [class.active]="isActive('dashboard')"><a [routerLink]="['dashboard']">Dashboard</a></li>
      <li [class.active]="isActive('requisitions')"><a [routerLink]="['requisitions']">Requisitions</a></li>
      <!-- <li [class.active]="isActive('applicants')"><a [routerLink]="['applicants']">Applicants</a></li> -->
      <li [class.active]="isActive('interview-templates')"><a [routerLink]="['interview-templates']">Interview Templates</a></li>
    </ul>

    <app-language></app-language>
    <app-profile></app-profile>
  </div>

  <router-outlet></router-outlet>
</div>
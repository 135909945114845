<app-menu-sidebar></app-menu-sidebar>
<div class="app-content leave-cms">

    <div class="app-header topfinance-nav" >
        <ul *ngIf="operation == true">
            <!-- <li [class.active]="isActive('employee-listing')" class="static-text" (click)="routerChange('employee-listing')"><a >{{translations?.listing}}</a></li> -->
            <!-- <li [class.active]="isActive('add')"  class="static-text" (click)="routerChange('add')" ><a >{{translations?.addEmployee}}</a></li> -->
            <li [class.active]="isActive('layoffs')" class="static-text"  (click)="routerChange('layoffs')"><a >{{translations?.layoffs}}</a></li>
            <li [class.active]="isActive('employee-listing')" class="static-text"  (click)="routerChange('employee-listing')"><a >{{translations?.listing}}</a></li>
           
           
            <!-- <li class="ms-3">
                <div  class="cursor-pointer" [matMenuTriggerFor]="menu" >
                    {{translations?.listing}}
                </div>
                <mat-menu #menu="matMenu">
                    <li mat-menu-item class="static-text" (click)="routerChange('employee-listing')"><a >{{translations?.employeeListing}}</a></li>
                    <li mat-menu-item class="static-text" (click)="routeToPage()"><a >{{translations?.onboardListing}}</a></li>
                     
               </mat-menu>
            </li> -->
        </ul>

        <app-language></app-language>
        <app-profile></app-profile>
    </div>
    <router-outlet></router-outlet>
</div>
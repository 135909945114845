import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LanguageTranslateService } from 'src/app/services/language-translate.service';

@Component({
  selector: 'app-leave',
  templateUrl: './leave.component.html',
  styleUrls: ['./leave.component.css']
})
export class LeaveComponent implements OnInit {
  currentLanguage$?: Subscription;
  lang!: string;
  translations: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private langService: LanguageTranslateService
  ) {
    this.route.paramMap.subscribe((params: any) => {
      this.lang = params.get('lang');
    });
  }
  ngOnInit(): void {
    this.currentLanguage$ = this.langService.currentLanguage$.subscribe(async () => {
      return new Promise<boolean>(async (resolve, reject) => {
        await this.langService.loadTranslations('leave', 'LeaveComponent')?.then((res: any) => {
          this.translations = res;
          resolve(true);
        }).catch((err: any) => {
          console.error(`Failed to load translations`, err);
          reject(false);
        });
      })
    });
  }
  routerChange(routeString: string) {
    this.router.navigate([this.lang, 'leave', routeString]);
  }
  isActive(routeString: string): boolean {
    if (this.router.url.includes(routeString)) {
      return true;
    }
    return false;
  }
}

import { Injectable } from '@angular/core';
import { WebService } from './web.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class AtsService {


  constructor(
    private webService: WebService,
    private spinner: NgxSpinnerService,
    private http:HttpClient
  ) { }

  getRequistionList(options:any){
    return this.webService.getAts('requistion/',options);
  }

  getTemplateList(options:any ){
    return this.webService.getAts('templates',options);
  }

  getMasterList(){
    return this.webService.simpleGetAts('master/list');
  }

  addTemplate(payload:any){
    return this.webService.postAts('templates', payload)
  }

  updateTemplate(payload:any){
    console.log(payload)
    return this.webService.updateAts('templates/' + payload?.id, payload)
  }


  getTempDetails(id:any){
    return this.webService.simpleGetAts('templates/' + id);
  }

  addRequistion(payload:any){
    return this.webService.postAts('requistion/create', payload)
  }

  getDepartmentTemplate(id:any){
    return this.webService.simpleGetAts("templates/list-department-wise/" + id);
  }

  getSingleRequistion(id:any){
    return this.webService.simpleGetAts('requistion/' + id);
  }

  updateRequistion(payload:any){
    console.log(payload)
    return this.webService.updateAts('requistion/update/' + payload?.id, payload)
  }

  checkRequistion(payload:any){
    return this.webService.postAts('applicant/checkReqLink', payload)
  }

  applicantForm(payload:any){
    return this.webService.postAts('applicant/create', payload)
  }

  applicantList(options:any, id:any){
    return this.webService.getAts('applicant/list/' + id, options)
  }

  sendForReview(payload:any){
    return this.webService.postAts('applicant/review', payload)
  }

  reviewList(options:any, id:any){
    return this.webService.getAts('applicant/list/'+ id + '/reviewed', options)
  }

  sendForShorlist(payload:any){
    return this.webService.postAts('applicant/shortlist', payload)
  }

  shortListedApplicant(options:any, id:any){
    return this.webService.getAts('applicant/list/'+ id + '/shortlisted', options)
  }

  intervierwTemplateList(id:any){
    return this.webService.simpleGetAts('interview/list/'+id)
  }

  interviewShedule(payload:any){
    return this.webService.postAts('interview/schedule', payload)
  }

  applicantForInterview(options:any, id:any){
    return this.webService.getAts('interview/list/'+ id, options)
  }

  hiringAppicant(payload:any){
    return this.webService.postAts('applicant/select', payload)
  }

  // async downloadFile(url: string, fileName: string, payload?: Object) {
  //   try {
  //     this.spinner.show();
  //     this.webService.simpleGet('company/' + url, payload).subscribe(
  //       (response: HttpResponse<any>) => {
  //         if (response.status !== 200 ||
  //           response.body.type == 'application/json') {
  //           throw { message: 'Something went wrong. Please try again later' };
  //         }
  //         saveAs(response.body, fileName);
  //         this.spinner.hide();
  //       },
  //       (err: any) => {
  //         this.spinner.hide();
  //         throw err;
  //       }
  //     );
  //   } catch (err: any) {
  //     this.spinner.hide();
  //     // this.toastr.error(err.message)
  //     throw err;
  //   }
  // }

  
  
  async downloadFileDirectly(url: string, fileName: string): Promise<void> {
    try {
      saveAs(url,fileName)
      // this.spinner.show(); // Show the spinner while the file is being downloaded
      
      // this.http.get(url, { responseType: 'blob' }).subscribe(
      //   (blob: Blob) => {
      //     // Check if the response is a valid blob
      //     if (!blob || blob.type === 'application/json') {
      //       throw new Error('Something went wrong. Please try again later.');
      //     }
  
      //     // Save the file using FileSaver.js
      //     saveAs(blob, fileName);
      //     this.spinner.hide(); // Hide the spinner after the file is downloaded
      //   },
      //   (err: any) => {
      //     this.spinner.hide();
      //     console.error('Error while downloading the file:', err);
      //     throw err;
      //   }
      // );
    } catch (err: any) {
      this.spinner.hide();
      console.error('Error caught during file download:', err);
      // Uncomment the line below to display error messages to the user
      // this.toastr.error(err.message);
      throw err;
    }
  }
  
  }



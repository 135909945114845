import { Injectable } from '@angular/core';
import { WebService } from './web.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private webService:WebService
  ) { }


  newJoinedEmployees(payload: any) {
    return this.webService.post('company/company-dashboard-get-new-joined-employees', payload);
  }
  getLeaveList(payload: any) {
    return this.webService.post('company/company-dashboard-leave-list', payload);
  }
  getUpcomingBirthdays(payload: any) {
    return this.webService.post('company/company-dashboard-upcoming-birthdays', payload);
  }
  getUpcomingWorkAnni(payload: any) {
    return this.webService.post('company/company-dashboard-upcoming-work-aniversary', payload);
  }
  getTotalEmployes(payload: any) {
    return this.webService.post('company/company-dashboard-total-data', payload);
  }
  latestAnnouncement(payload: any) {
    return this.webService.post('company/company-dashboard-latest-announcement', payload);
  }
  latestNotice(payload: any) {
    return this.webService.post('company/company-dashboard-upcoming-expire-documents', payload);
  }
  getNotice(payload: any) {
    return this.webService.post('company/company-dashboard-expire-and-expiring-documents', payload);
  }
  getDashboardCTCData(payload:any){
    return this.webService.postFormData(
      'company/company-dashboard-ctc-data',
      payload
    )
  }
}

import { Injectable } from '@angular/core';
import { WebService } from './web.service';

@Injectable({
  providedIn: 'root'
})
export class AnnouncementService {

  constructor(
    private webService:WebService
  ) { }

  getAnnouncements(payload: any) {
    return this.webService.post('company/get-announcement-list', payload);
  }
  getAnnouncementDetails(payload: any) {
    return this.webService.post('company/get-announcement-details', payload);
  }
  getReplyAnnouncements(payload: any) {
    return this.webService.post('company/announcement-reply-list', payload);
  }
  createReply(payload: any) {
    return this.webService.post('company/company-dashboard-create-reply', payload);
  }

  createAnnouncement(payload:any){
    return this.webService.postFormData('company/create-announcement', payload);
  }

  updateAnnouncement(payload:any){
    return this.webService.postFormData('company/update-announcement', payload);
  }
  fetchAnnouncementData(payload:any){
    return this.webService.postFormData('company/get-announcement-list', payload);
  }

  updateAnnouncementStatus(payload:any){
    return this.webService.postFormData('company/update-publish-status', payload);
  }

  deleteAnnouncement(payload:any){
    return this.webService.postFormData('company/delete-announcement', payload);
  }

  fetchAnnouncementCommentData(payload:any){
    return this.webService.postFormData('company/announcement-reply-list', payload);
  }
}

import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, OnInit, Renderer2, RendererFactory2 } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import * as Global from '../shared/globals';

@Injectable({
  providedIn: 'root'
})
export class LanguageTranslateService implements OnInit {
lang:any="en"
  Global = Global;
  currentLanguageSubject = new BehaviorSubject<string>(localStorage.getItem('lang') || 'en');
  currentLanguage$ = this.currentLanguageSubject.asObservable();
  private renderer: Renderer2;
  constructor(
    private http: HttpClient,
    private router: Router,
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }
  ngOnInit(): void {
    this.currentLanguageSubject.next(this.getLangLocalStorage() || 'en');
  }
  async loadTranslations(module: string, fileName: string) {
    return new Promise(async (resolve, reject) => {
      const lang = this.getLangLocalStorage() || 'en';
      const filePath = `/assets/i18n/${lang}/${module}/${fileName}.json`;
      await this.http.get(filePath).subscribe({
        next: (res: any) => {
          resolve(res);
        },
        error: (err: any) => {
          console.error(`Failed to load translations from ${filePath}:`, err);
          return;
        }
      });
    })
  }
  setLangLocalStorage(lang: string) {
    localStorage.setItem('lang', lang);
  }
  // getLangLocalStorage() {
  //   return localStorage.getItem('lang')?.toString();
  // }

  getLangLocalStorage() {
    return localStorage.getItem('lang') || 'en'
  }
  loadCss(lang: string) {
    const head = this.document.getElementsByTagName('head')[0];
    const linkElements = document.head.querySelectorAll('link[rel="stylesheet"]');
    linkElements.forEach((link: any) => {
      if (link.href.includes(`${lang}Style`)) {
        const dynamicLink = this.renderer.createElement('link');
        this.renderer.setAttribute(dynamicLink, 'rel', 'stylesheet');
        this.renderer.setAttribute(dynamicLink, 'type', 'text/css');
        this.renderer.setAttribute(dynamicLink, 'href', link.href);
        this.renderer.appendChild(head, dynamicLink);
      }
    });
  }
  unloadCss(lang: string) {
    // Remove existing css except current
    Global?.languageMaster()?.filter((langItem: any) => {
      if (langItem?.value !== lang) {
        const linkElement = this.document.querySelector(`link[rel="stylesheet"][href*="${langItem?.value}Style"]`);
        if (linkElement) {
          this.renderer.removeChild(linkElement.parentNode, linkElement);
        }
      }
    });
  }
  changeLanguage(language: string): void {
    this.currentLanguageSubject.next(language);
  }
  initiateChangeLanguage(language: string): void {
    this.setLangLocalStorage(language);
    // if (language == 'ar') {
    //   document.body.style.transform = 'scaleX(-1)';
    // } else {
    //   document.body.style.transform = 'scaleX(1)';
    // }
    const currentUrl = this.router.url;
    const segments = currentUrl.split('/');
    segments[1] = language;
    const newUrl = segments.join('/');
    this.router.navigateByUrl(newUrl);
    this.changeLanguage(language);
  }
}

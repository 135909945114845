import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageTranslateService } from 'src/app/services/language-translate.service';
import * as Global from 'src/app/shared/globals';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.css']
})
export class LanguageComponent implements OnInit {
  Global = Global;
  languages: any[] = [];
  lang!: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private langService: LanguageTranslateService,
  ) {
    this.languages = Global.languageMaster();
    this.route.paramMap.subscribe((params: any) => {
      this.lang = params.get('lang');
    });
  }
  ngOnInit(): void { }
  async onChangeLanguage(event: any) {
    const selectedLanguage = event.target.value;
    const lang = this.langService.getLangLocalStorage() ?? 'en';

    if (lang === selectedLanguage) {
      return;
    }

    this.langService.initiateChangeLanguage(selectedLanguage);
  }
}

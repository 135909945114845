import { Injectable } from '@angular/core';
import { WebService } from './web.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { HttpResponse } from '@angular/common/http';
import { saveAs } from 'file-saver';

declare const Razorpay: any;

@Injectable({
  providedIn: 'root',
})
export class RazorpayPaymentService {
  constructor(
    private webService: WebService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {}

  get nativeWindow(): any {
    return window; // Correctly reference the global window object
  }

  createRazorpayInstance(options: any): any {
    return new this.nativeWindow.Razorpay(options);
  }

  getCreditSettingsValue() {
    return this.webService.post('company/get-credit-setting-value', {});
  }

  getCreditPurchaseOrderID(payload: any) {
    return this.webService.post('company/get-order-id', payload);
  }

  verifyCreditPurchasePayment(payload: any) {
    return this.webService.post('company/verify-order-id', payload);
  }

  verifyCreditPurchaseCoupon(payload: any) {
    return this.webService.post('company/check-coupon-code', payload);
  }

  fetchCreditPurchaseTransactionHistory(payload: any) {
    return this.webService.post('company/get-payment-history', payload);
  }

  async downloadFile(url: string, fileName: string, payload?: Object) {
    try {
      this.spinner.show();
      await this.webService.simpleGet('company/' + url, payload).subscribe(
        (response: HttpResponse<any>) => {
          if (
            response.status !== 200 ||
            response.body.type == 'application/json'
          ) {
            throw { message: 'Something went wrong. Please try again later' };
          }
          saveAs(response.body, fileName);
          this.spinner.hide();
        },
        (err: any) => {
          this.spinner.hide();
          throw err;
        }
      );
    } catch (err: any) {
      this.spinner.hide();
      // this.toastr.error(err.message)
      throw err;
    }
  }
}

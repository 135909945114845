import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }
  getToken(): string | null {
    return localStorage.getItem('hrmlix-uae-companyuser-token');
  }
}

<div class="modalmain">
  <div class="container">
    <div class="row height_h100 align-items-center justify-content-center">
      <div class="col-lg-12 p-0">
        <div class="">
          <div class="p-15 py-3 modal-header bg-light">
            <div class="modal-text">
              <h3 class="derktext left-line mb-0">
                <span class="static-text"> {{translations?.PurchaseCredit}} </span>
              </h3>
            </div>
            <span class="btn-close btn ms-4 ms-auto" mat-dialog-close
              ><i class="bi bi-x-lg"></i
            ></span>
          </div>
        </div>
        <div class="">
          <div class="p-15 py-4 border-bottom">
            <div class="risection">
              <div [formGroup]="creditForm" class="row">
                <div class="col-12">
                  <div class="mb-3 form-group amount">
                    <label for="" class="form-label">{{translations?.creditAmount}} *</label>
                    <input
                      (input)="calculatePayableTotal('credit_amount')"
                      type="number"
                      formControlName="credit_amount"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-12">
                  <div class="mb-3 form-group">
                    <label for="" class="form-label">{{translations?.creditToken}} *</label>
                    <input
                      (input)="calculatePayableTotal('credit_coupon')"
                      type="number"
                      formControlName="credit_coupon"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-12">
                  <div class="mb-3 form-group">
                    <label for="" class="form-label">{{translations?.couponCode}} *</label>
                    <div class="d-flex align-items-center">
                      <input
                        formControlName="coupon_code"
                        type="text"
                        class="form-control"
                      />
                      <!-- style="all:unset" -->
                      <button 
                      *ngIf="!appliedCoupon"
                        class="flex-1 m-0 ms-2 text-primary font-bold btn button-solide btn-border"
                        [disabled]="creditForm.invalid || !creditForm.get('coupon_code')?.value"
                        (click)="applyCouponCode()">
                        {{translations?.applyBtnTxt}}
                      </button>
                      <div *ngIf="appliedCoupon" class="me-2">
                        <i style="font-size: 30px;" class="bi-check-lg text-success"></i>
                      </div>
                      <button 
                      *ngIf="appliedCoupon"
                        class="flex-1 m-0 btn button-solide text-bg-danger"
                        (click)="removeCouponCode()">
                        {{translations?.removeBtnTxt}}
                      </button>
                    </div>
                  </div>
                </div>

                <div class="d-flex align-items-center justify-content-between">
                  <div>
                    AED: {{creditForm.get('payable_amount')?.value || 0.00 | number:"1.1-2"}}
                    ({{translations?.IncVat}})
                  </div>
                  <div>
                    <button
                    (click)="purchaseCredit()"
                    [disabled]=" creditForm.invalid || creditForm.get('payable_amount')?.value < 0"
                    class="btn button-solide bg-blue w-100px ms-3 flip-text px-3"
                      >
                      {{translations?.payNowBtnTxt}}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { HttpClient, HttpHandler } from '@angular/common/http';
import * as Global from 'src/app/shared/globals';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Response } from '../http-handeler/interfaces/response'
@Injectable({
  providedIn: 'root'
})
export class WebService {
  readonly BASE_URL!:string;
  readonly BASE_HREF!:string;
  readonly BASE_ATS_URL!:string;
  readonly BASE_HREF_ATS!:string;

  constructor(
    private http:HttpClient
  ) 
  { 
    this.BASE_URL = environment.apiUrl;
    this.BASE_HREF = environment.BASEHREF;
    this.BASE_ATS_URL = environment.apiAtsUrl;
    this.BASE_HREF_ATS = environment.BASEHREF_ATS

  }

  get(url:string){
    return this.http.get<any>(`${this.BASE_URL}/${this.BASE_HREF}/${url}`)
  }
// Ats
  getAts(url:string, options:any){  
    console.log(options)
    return this.http.get<any>(`${this.BASE_ATS_URL}/${this.BASE_HREF_ATS}/${url}`, options)
  }
  simpleGetAts(url:string){
    return this.http.get<any>(`${this.BASE_ATS_URL}/${this.BASE_HREF_ATS}/${url}`)
  }

   postAts(url:string, payload:any){
    return this.http.post<any>(`${this.BASE_ATS_URL}/${this.BASE_HREF_ATS}/${url}`, payload)
    
    // .then((response:Response)=>{
    //   if (response?.status !== 'success') {
    //     if (response?.val_msg) {
    //       const {val_msg} = response;
    //       for (const key in val_msg) {
    //         if (Object.prototype.hasOwnProperty.call(val_msg, key)) {
    //           const element:any = val_msg[key];
    //           if (element.message) {
    //             return this.handleError(
    //               element.message || 'Something went wrong!'
    //             )
    //           }
              
    //         }
    //       }
    //       return 'Validation Error: Please check all the fields correctly';
    //     }
    //     return this.handleError(
    //       response?.error || response?.message || response?.code || 'Something went wrong!'
    //     );
    //   }
    //   return response;
    // }).catch(this.handleError)
  }

  postAtsFormData(url: string, payload: any) {
    var formData = new FormData();
    for (var key in payload) {
      formData.append(key, payload[key]);
    }
    // return this.http.post<any>(`${this.Base_Url}/${url}`, formData);
    return this.http.post<any>(`${this.BASE_ATS_URL}/${this.BASE_HREF_ATS}/${url}`, payload)
  }
  updateAts(url:string, payload:any){
    return this.http.put<any>(`${this.BASE_ATS_URL}/${this.BASE_HREF_ATS}/${url}`, payload)
  }


  // Ats End

  async post(url:string, payload:any){
    return await firstValueFrom(
      this.http.post<any>(`${this.BASE_URL}/${this.BASE_HREF}/${url}`, payload)
    ).then((response:Response)=>{
      if (response?.status !== 'success') {
        if (response?.val_msg) {
          const {val_msg} = response;
          for (const key in val_msg) {
            if (Object.prototype.hasOwnProperty.call(val_msg, key)) {
              const element:any = val_msg[key];
              if (element.message) {
                return this.handleError(
                  element.message || 'Something went wrong!'
                )
              }
              
            }
          }
          return 'Validation Error: Please check all the fields correctly';
        }
        return this.handleError(
          response?.error || response?.message || response?.code || 'Something went wrong!'
        );
      }
      return response;
    }).catch(this.handleError)
  }
  postFormData(url: string, payload: any) {
    var formData = new FormData();
    for (var key in payload) {
      formData.append(key, payload[key]);
    }
    // return this.http.post<any>(`${this.Base_Url}/${url}`, formData);
    return this.post(url, formData);
  }
  private handleError(error: any): Promise<any> {
    if (error.status === 0) {
      return Promise.reject<string>('There is no internet connection');
    }
    // if (error.status) {
    //   if (error.status === 401) {
    //     localStorage.clear();
    //     window.location.href = '/';
    //     return Promise.reject<string>(error.statusText + '! No user found with your token');
    //   }
    //   if (error.status === 403) {
    //     localStorage.clear();
    //     window.location.href = '/';
    //     return Promise.reject<string>('Your are logged Out');
    //   }
    //   return Promise.reject<string>(error?.error?.message);
    // }
    // if (
    //   (error.message) ||
    //   error == 'No user found with your token'
    // ) {
    //   localStorage.clear();
    //   window.location.href = '/';
    // }
    return Promise.reject<string>(error.message || error);
  }
  simpleGet(url: string, payload: Object = {}) {
    return this.http.post(`${this.BASE_URL}/${this.BASE_HREF}/${url}`, payload, { responseType: 'blob', observe: 'response' });
  }
}

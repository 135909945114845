import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { EmployeeProfileService } from 'src/app/services/employee-profile.service';
import { LanguageTranslateService } from 'src/app/services/language-translate.service';

@Component({
  selector: 'app-employee',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  lang:any="en"

  currentLanguage$?: Subscription;
  currentRoute$?: Subscription;
  operation: boolean = true;
  
  translations: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private langService: LanguageTranslateService,
    private empService:EmployeeProfileService
  ) {
    this.route.paramMap.subscribe((params: any) => {
      this.lang = params.get('lang');
    });
  }
  ngOnInit(): void {
    this.currentLanguage$ = this.langService.currentLanguage$.subscribe(async () => {
     this.lang = this.langService.getLangLocalStorage();
      return new Promise<boolean>(async (resolve, reject) => {
        await this.langService.loadTranslations('profile', 'ProfileComponent')?.then((res: any) => {
          this.translations = res;
          resolve(true);
        }).catch((err: any) => {
          console.error(`Failed to load translations`, err);
          reject(false);
        });
      })
    });

    console.log(this.router.url,'prof route');
    let r = this.router.url.split('/');
    console.log(r[2])
    
   if (r[3] == 'employee-listing' || r[3] == 'layoffs') {
      this.operation = true;
  }
    
    // this.currentRoute$ = this.empService.listingRoute$.subscribe((res:any)=>{
    //   this.isActive(res)
    // })
  }
  ngOnDestroy() {
    this.currentLanguage$?.unsubscribe();
  }
  routerChange(routeString: string) {
    this.router.navigate([this.lang, 'employees', routeString]);
    console.log(routeString)
  }
  isActive(routeString: string): boolean {
    if (this.router.url.includes(routeString)) {
      return true;
    }
    return false;
  }

 
  routeToPage(){
    this.router.navigate(
      [this.lang, 'employees', 'onboard-listing', 'onboard-emp-list']);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AcceptanceFormRoutingModule } from './acceptance-form-routing.module';
import { AcceptanceFormComponent } from './acceptance-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SignaturePadModule } from 'angular-signature-pad-v2';


@NgModule({
  declarations: [
    AcceptanceFormComponent
  ],
  imports: [
    CommonModule,
    AcceptanceFormRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SignaturePadModule
  ],
  exports:[AcceptanceFormComponent]
})
export class AcceptanceFormModule { }

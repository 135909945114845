import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import PaginationOptions from 'src/app/models/PaginationOptions';
import { AnnouncementService } from 'src/app/services/announcement.service';
import { LanguageTranslateService } from 'src/app/services/language-translate.service';
import * as Global from 'src/app/shared/globals';

@Component({
  selector: 'app-comment-view-modal',
  templateUrl: './comment-view-modal.component.html',
  styleUrls: ['./comment-view-modal.component.css']
})
export class CommentViewModalComponent implements OnInit{
lang:any = 'en'
  paginationOptions: PaginationOptions = new PaginationOptions();
  announceId:any;
  announcement_comment_data:any[]= [];
  announcement_title:any;
  currentLanguage$?: Subscription;
  translations: any;
  isLoading!:boolean;
  ;

  constructor(
    private dialogRef: MatDialogRef<CommentViewModalComponent>,
    private spinner:NgxSpinnerService,
    private announcementService: AnnouncementService,
    private toastr: ToastrService,
    private langService: LanguageTranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private activatedRoute: ActivatedRoute
  ){

  }

  ngOnInit(): void {
    // console.log(this.data);
   
    this.currentLanguage$ = this.langService.currentLanguage$.subscribe(async () => {
this.lang = this.langService.getLangLocalStorage();
      return new Promise<boolean>(async (resolve, reject) => {
        await this.langService.loadTranslations('announcement', 'CommentViewModalComponent')?.then((res: any) => {
          this.translations = res;
          resolve(true);
        }).catch((err: any) => {
          console.error(`Failed to load translations`, err);
          reject(false);
        });
      })
    });
 
     this.lang = this.langService.getLangLocalStorage();
    //  console.log(this.lang)
   
    this.announcement_title = this.data?.item?.title;
    this.announceId = this.data?.item?._id;
    // console.log(this.announceId)
    this.fetchAnnouncementComment();

  
  }

  closeDialog(){
    this.dialogRef.close();
  }

  fetchAnnouncementComment( ) {
    let page:any = null;
    return new Promise((resolve, reject) => {
      if (page != null) {
        this.paginationOptions.page = page;
      }

      this.spinner.show();
      // console.log(this.announceId);
      this.announcementService.fetchAnnouncementCommentData({
        'pageno': this.paginationOptions.page,
        'parent_id': this.announceId,
        
      }).then(res => {
        if (res.status == 'success') {
          //  console.log(res);
          this.announcement_comment_data = res.data.docs;
          // console.log(this.announcement_comment_data)

            this.paginationOptions = {
            hasNextPage: res.data.hasNextPage,
            hasPrevPage: res.data.hasPrevPage,
            limit: res.data.limit,
            nextPage: res.data.nextPage,
            page: res.data.page,
            pagingCounter: res.data.pagingCounter,
            prevPage: res.data.prevPage,
            totalDocs: res.data.totalDocs,
            totalPages: res.data.totalPages,
          };
        } else {
          this.toastr.error(res.message);

          this.announcement_comment_data = [];
          this.paginationOptions = {
            hasNextPage: false,
            hasPrevPage: false,
            limit: Global.DataTableLength,
            nextPage: null,
            page: 1,
            pagingCounter: 1,
            prevPage: null,
            totalDocs: 0,
            totalPages: 1,
          };
        }

        this.spinner.hide();
        
        resolve(true);
      }, (err) => {
        this.announcement_comment_data = [];
        this.paginationOptions = {
          hasNextPage: false,
          hasPrevPage: false,
          limit: Global.DataTableLength,
          nextPage: null,
          page: 1,
          pagingCounter: 1,
          prevPage: null,
          totalDocs: 0,
          totalPages: 1,
        };

        this.spinner.hide();
        this.toastr.error(err);
        
        resolve(true);
      });
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LanguageTranslateService } from 'src/app/services/language-translate.service';
import * as Global from 'src/app/shared/globals';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  lang!: string;
  Global = Global;
  currentLanguage$?: Subscription;

  translations: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private langService: LanguageTranslateService,
  ) { }
  ngOnInit(): void {
    this.route.paramMap.subscribe((params: any) => {
      this.lang = params.get('lang');
    });
    this.currentLanguage$ = this.langService.currentLanguage$.subscribe(async () => {
      return new Promise<boolean>(async (resolve, reject) => {
        await this.langService.loadTranslations('settings', 'SettingsComponent')?.then((res: any) => {
          this.translations = res;
          resolve(true);
        }).catch((err: any) => {
          console.error(`Failed to load translations`, err);
          reject(false);
        });
      })
    });
  }
  routerChange(routeString: string) {
    this.router.navigate([this.lang, 'settings', routeString]);
  }
  isActive(routeString: string): boolean {
    // if (this.router.url.includes(routeString)) {
    //   return true;
    // }
    // return false;

    const url = this.router.url.split("/");
    
    if (url[3] == routeString) {
      return true;
    }
    return false;
  }
}



  <div class="app-content leave-cms">
    <app-menu-sidebar></app-menu-sidebar>

    <div class="app-header topfinance-nav">
          <app-language class="ms-auto"></app-language>
          <app-profile></app-profile>

    </div>

    <div class="info-fluid">
        <div class="card info_box">
          <div class="p-15 d-flex justify-content-between align-items-center">
            <h6 class="mb-0">{{translations?.announcementList}}</h6>
            <!-- <h6 class="mb-3">{{translations?.announcement}}</h6> -->
            <!-- <h6 class="mb-0"*ngIf="editActionId == ''; else editHeader">{{translations?.create}}</h6> -->
            <!-- <ng-template #editHeader>
                <h6 class="br-section-label mb-0">{{translations?.update}}</h6>
            </ng-template> -->
            <button class="btn button-solide btn-border ms-auto"  *ngIf="
            Global.checkCompanyModulePermission({company_module: 'announcement',
              company_sub_module: 'announcement',
              company_sub_operation: ['add'],
              company_strict: true
            })" (click)="openDialog('create')">{{translations?.addAnnouncement}}</button>
          </div>
          <!-- <div class="card-header h-auto pt-3 d-block">
            <form [formGroup]="announceForm">
            <div class="row" id="announce-form">
              <div class="col-lg-8">
                <div class="row">
                  <div class="col-lg-6 mt-4">
                    <div class="form-group position-relative mb-0 flex-grow-1">
                      <label for="">{{translations?.title}} *</label>
                      <input type="text" formControlName="title" placeholder="{{translations?.enterTitleName}}" class="form-control">
                      <p class="error-element" *ngIf="announceForm.controls['title'].hasError('required') && (announceForm.controls['title'].dirty || announceForm.controls['title'].touched)">{{translations?.thisFieldIsRequired}}</p>
                    </div>   
                  </div>
                  <div class="col-lg-6 mt-4">       
                    <div class="form-group position-relative mb-0 flex-grow-1">
                      <label for="">{{translations?.publishStatus}} *</label>
                      <ngx-select-dropdown formControlName="publish_status" [options]="publishStatusMaster" (change)="getPublishStatusMaster($event)" class="form-control p-0 border-0" [config]="{search: true, placeholder: 'Choose One', 'height': '200px'}" [multiple]="false"></ngx-select-dropdown>
                      <p class="error-element" *ngIf="announceForm.controls['publish_status'].hasError('required') && (announceForm.controls['publish_status'].dirty || announceForm.controls['publish_status'].touched)">{{translations?.thisFieldIsRequired}}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-8 mt-4">
                <div class="d-flex flex-wrap">
                  <div class="form-group position-relative mb-0 flex-grow-1">
                    <label for="">{{translations?.description}} *</label>
                    <textarea name="" formControlName="description" placeholder="{{translations?.enterDescription}}" class="form-control" style="height:100px; padding: 12px;"></textarea>
                    <p class="error-element" *ngIf="announceForm.controls['description'].hasError('required') && (announceForm.controls['description'].dirty || announceForm.controls['description'].touched)">{{translations?.thisFieldIsRequired}}</p>  
                </div>
                </div>
              </div>
              <div class="col-lg-8 mt-4">                    
              
                <button type="submit" [disabled]="!announceForm.valid" (click)="create($event)" class="btn button-solide" *ngIf="editActionId == ''; else editButtonSection">
                    <span >{{translations?.add}}</span>
                </button>

                <ng-template #editButtonSection>
                    <button type="button" (click)="cancelEntry()" class="btn button-solide me-3">
                        <span>{{translations?.cancelEditing}}</span>
                    </button>
                    <button type="submit" [disabled]="!announceForm.valid" (click)="update($event)" class="btn button-solide">
                        <span>{{translations?.update}}</span>
                    </button>
                </ng-template>
              </div>
            </div>  
            </form>    
          </div> -->
          <ng-container  *ngIf="
          Global.checkCompanyModulePermission({   company_module: 'announcement',
            company_sub_module: 'announcement',
            company_sub_operation: ['view','edit','delete'],
            company_strict: true
          }); else permission
         ">
         <app-custom-table-filter (onPageFilter)="paginationOptions!.limit = $event ;fetch(1)"></app-custom-table-filter>
          <div class="table-responsive table-left">
          
                <table class="table table-bordered shift-allowance">
                  <tbody>
                    <tr>
                      <!-- <td style="background-color: #F5F6FA; width: 12%; text-align: left;">{{translations?.slNo}}</td> -->
                      <td style="background-color: #F5F6FA; width: 12%; text-align: left;">{{translations?.publishStatus}}</td>
                      <td style="background-color: #F5F6FA; width: 12%; text-align: left;">{{translations?.title}}</td>
                      <td style="background-color: #F5F6FA; width: 12%; text-align: center;">{{translations?.comments}}</td>
                      <td style="background-color: #F5F6FA; width: 12%; text-align: center;">{{translations?.action}}</td>
                    </tr>
                    <tr *ngFor="let item of announce_data; let i = index">
                        <!-- <td style="text-align: left;">{{i+1}}</td> -->
                        <td style="text-align: left;">
                          <div class="d-flex align-items-center swicharbtn mb-0">
                              <label class="switch me-13" *ngIf="
                              Global.checkCompanyModulePermission({company_module: 'announcement',
                                company_sub_module: 'announcement',
                                company_sub_operation: ['edit'],
                                company_strict: true
                              })" (change)="toggleStatus(item)">
                                <input type="checkbox"  [attr.checked]="item?.publish_status == 'published' ? true : null " >
                                <span class="slider round"></span></label>  {{item.publish_status | titlecase}}
                          </div>
                         
                      </td>
                        <td style="text-align: left;">{{item?.title}}</td>
                        <td style="text-align: center;">
                            <div class="d-flex justify-content-center">
                                <button [disabled]="item?.publish_status == 'private'" *ngIf="
                                Global.checkCompanyModulePermission({company_module: 'announcement',
                                  company_sub_module: 'announcement',
                                  company_sub_operation: ['view'],
                                  company_strict: true
                                })" (click)="routeToanncDetails(item?._id)" class="btn button_action blue_action"><i class="bi bi-eye-fill"></i></button>
                            </div>
                        </td>
                        <td style="text-align: center;">
                            <div class="d-flex justify-content-center">
                                <button class="btn button_action me-2" *ngIf="
                                Global.checkCompanyModulePermission({company_module: 'announcement',
                                  company_sub_module: 'announcement',
                                  company_sub_operation: ['edit'],
                                  company_strict: true
                                })" (click)="openDialog(item)"><i class="bi bi-pencil-fill"></i></button>
                                <button *ngIf="
                                Global.checkCompanyModulePermission({company_module: 'announcement',
                                  company_sub_module: 'announcement',
                                  company_sub_operation: ['delete'],
                                  company_strict: true
                                })" class="btn button_action red_action" (click)="deleteItem(item)"><i class="bi bi-trash-fill"></i></button>
                            </div>
                        </td>   
                    </tr>
                     <tr>
                        <td *ngIf="announce_data?.length == 0" colspan="12" class="text-center">{{translations?.noRecordsFound}}</td>             
                    </tr>
                  </tbody>
              </table>
              <app-custom-pagination
              [rows]="announce_data" [paginationOptions]="paginationOptions" (onPageClicked)="fetch($event)"
              ></app-custom-pagination>
          </div>
        </ng-container>
        <ng-template #permission>
        <app-permission></app-permission>
      </ng-template>
        </div>
      </div>
  
    

</div>
<div class="ms-2 text-end">
 <div class="d-flex">
    <div *ngIf="user_data?.user_type == 'staff'; else com">
        <span class="d-block fw-bold">{{user_data?.first_name}} {{user_data?.last_name}}</span>
        <span>({{user_data?.userid}})</span>
    </div>
    <ng-template #com>
        <div>
        <span class="d-block fw-bold">{{user_data?.details?.establishment_name}}</span>
        <span>({{user_data?.details?.corporate_id}})</span>
        </div>
    </ng-template>
    <ul class="dashrgt">
        <!-- <li> -->
            <!-- <div class="chartbox">
                <span>Your profile is incomplete</span>
                <div class="chartimg">
                    <em>95%</em>
                    <img src="assets/images/round.png" alt="Logo">
                </div>
            </div> -->
        <!-- </li> -->
        <li class="ms-3">
            <div  class="cursor-pointer" [matMenuTriggerFor]="menu" >
            <ng-container *ngIf="company_logo; else noComlogo">
                <div class="profileimg" >
                    <img [src]="company_logo"  alt="user">
                </div>
            </ng-container>
            <ng-template #noComlogo >
                <div class="profileimg">
                    <img src="assets/images/user.jpg"  alt="user">
                </div>
            </ng-template>
            </div>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="changePassword(company_id)">{{translations?.changePassword}}</button>
                <button mat-menu-item (click)="logout()" >{{translations?.logout}}</button>    
           </mat-menu>
        </li>
    </ul>
 </div>
</div>

<div class="ht-60 py-3 d-flex align-items-center justify-content-between custom-pagination">
    <div class="pagenationtitle">{{translations?.showing}} {{(rows.length > 0) ? paginationOptions.pagingCounter : 0}} {{translations?.to}} {{ (rows.length > 0) ? paginationOptions.pagingCounter + (rows.length - 1) : 0}} {{translations?.of}} {{paginationOptions.totalDocs}} {{translations?.records}}</div>
    <ul class="pagination pagination-basic mb-0">
        <li class="page-item" [class.disabled]="paginationOptions.totalPages <= 1 || paginationOptions.page == 1">
            <button class="page-link" style="z-index: 0 !important;" (click)="this.onPageClicked.emit(1)">{{translations?.first}}</button>
        </li>
        <li class="page-item" [class.disabled]="!paginationOptions.hasPrevPage">
            <button class="page-link" style="z-index: 0 !important;" (click)="this.onPageClicked.emit(paginationOptions.prevPage)">{{translations?.previous}}</button>
        </li>


        <li class="page-item active">
            <button class="page-link" style="z-index: 0 !important;">{{paginationOptions.page}}</button>
        </li>

        <li class="page-item" [class.disabled]="!paginationOptions.hasNextPage">
            <button class="page-link" style="z-index: 0 !important;" (click)="this.onPageClicked.emit(paginationOptions.nextPage)">{{translations?.next}}</button>
        </li>
        <li class="page-item" [class.disabled]="paginationOptions.totalPages <= 1 || paginationOptions.page == paginationOptions.totalPages">
            <button class="page-link" style="z-index: 0 !important;" (click)="this.onPageClicked.emit(paginationOptions.totalPages)">{{translations?.last}}</button>
        </li>
    </ul>
</div>
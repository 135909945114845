<div class="app-content leave-cms">
    <app-menu-sidebar></app-menu-sidebar>

    <div class="app-header topfinance-nav">
        <ul>
            <li [class.active]="isActive('yearly-holiday')" class="static-text"><a (click)="routerChange('yearly-holiday')">{{translations?.yearlyHoliday}}</a></li>
            <li [class.active]="isActive('weekly-holiday')" class="static-text"><a (click)="routerChange('weekly-holiday')">{{translations?.weeklyHoliday}}</a></li>
        </ul>

        <app-language></app-language>
        <app-profile></app-profile>
    </div>

    <router-outlet></router-outlet>

</div>
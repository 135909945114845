<div>
    <div class="f-label">
      <ng-content  select=".f-label"></ng-content>
    </div>
    <div class="form-group" [ngClass]="radius?'radius':''" [ngClass]="{'b-red' :control?.['touched'] && control?.['invalid']  }">
      <div class="f-prefix">
        <ng-content  select=".f-prefix"></ng-content>
      </div>
      <div class="form-control-wapper pl-2"  >
        <ng-content select=".f-control"></ng-content>
      </div>
      <div class="form-append">
        <ng-content select=".f-append"></ng-content>
      </div>
    </div>
    <div class="text-xs text-red" *ngIf="control?.['touched'] && control?.['invalid']">
      {{ error }}
    </div>
  </div>
<div class="borderredius-0">
    <div class="p-15 modal-header bg-light border-bottom">
        <div class="modal-text">
            <h3 class="derktext left-line static-text mb-0">{{translations?.viewDoc}}</h3>
        </div>
        <button class="btn-close btn ms-4 ms-auto" (click)="closeDialog()"><i class="bi bi-x-lg"></i></button>
    </div>
    <div class="p-15 modal-body border-bottom c">
        <div class="risection">
            

                <ng-container *ngIf="imagePath; else loadingTemplate">
                    <iframe class="pdf" [src]="imagePath" width="800" height="500"></iframe>
                  </ng-container>
                  <ng-template #loadingTemplate>
                    <div class="d-flex justify-content-center align-items-center">
                      <p class="text-center">{{translations?.noDocFound}}</p>
                    </div>
                  </ng-template>
            
        </div>
    </div>
</div>
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from '../services/storage.service';
import { LanguageTranslateService } from 'src/app/services/language-translate.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyuserGuard {

  constructor(
    private storage: StorageService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isAuthenticated = this.storage.getToken();
    const selectedLanguage = localStorage.getItem('lang') ?? 'en';
    if (!isAuthenticated) {
      this.router.navigateByUrl(`/${selectedLanguage}/login`);
      return false;
    }
    else {
      return true;
    }
  }

}

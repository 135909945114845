<div class="ms-3 " [formGroup]="TableOptionForm">
    <label>
        Show
        <select formControlName="length" class="custom-select"
                (change)="filterChanged()">

            <option *ngFor="let item of Global.DataTableLengthChangeMenu"
                    value="{{item}}">{{item}}</option>
        </select>
       entries 
    </label>
</div>
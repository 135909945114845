<div class="info-fluid height100 p-0">

    <div class="px-0 pt-3 modal-header bg-light">
      <div class="modal-text">
        <h6 class="m-0">{{ translations?.title }}</h6>
      </div>
      <span class="btn-close btn ms-4 ms-auto" mat-dialog-close><i class="bi bi-x-lg"></i></span>
    </div>
    
    <div class="table-responsive table-left">
      <table class="table table-bordered shift-allowance">
        <tbody>
          <tr>
            <td>{{ translations?.sl }}</td>
            <td>{{ translations?.date }}</td>
            <td>{{ translations?.invoiceNo }}</td>
            <td>{{ translations?.transactionNo }}</td>
            <td>{{ translations?.gateway }}</td>
            <td>{{ translations?.gatewayId }}</td>
            <td>{{ translations?.invValue }}</td>
            <td>{{ translations?.paymentValue }}</td>
            <td>{{ translations?.mode }}</td>
            <td>{{ translations?.status }}</td>
            <td>{{ translations?.receipt }}</td>
          </tr>
          <tr *ngFor="let history of paymentHistoryList; let i = index">
            <td>{{ i + 1 }}</td>
            <td>{{ history?.created_at | date : "dd/MM/YYYY" }}</td>
            <td>{{ history?.inv_id ?? "N/A" }}</td>
            <td>{{ history?.razorpay_payment_id ?? "N/A" }}</td>
            <td>Razorpay</td>
            <td>{{ history?.razorpay_order_id ?? "N/A" }}</td>
            <td>{{ (history?.credit_qty | number) ?? "N/A" }}</td>
            <td>{{ (history?.credit_amount | number) ?? "N/A" }}</td>
            <td>{{ (history?.method | titlecase) ?? "N/A" }}</td>
            <td>{{ (history?.payment_details?.status | titlecase) ?? "N/A" }}</td>
            <td>
              <button (click)="downloadPaymentHistory(history?._id, history?.created_at)" class="btn button_action blue_action">
                <i class="bi bi-download blue_action"></i>
              </button>
            </td>
          </tr>
          <tr *ngIf="!paymentHistoryList?.length">
            <td colspan="12" class="text-center">{{translations?.nodataFound}}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="p-0">
      <app-custom-pagination [rows]="paymentHistoryList" [paginationOptions]="paginationOptions" (onPageClicked)="paginationOptions.page = $event; fetchPaymentHistory()"></app-custom-pagination>
    </div>

</div>

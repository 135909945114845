import { Injectable } from '@angular/core';
import { WebService } from './web.service';

@Injectable({
  providedIn: 'root'
})
export class NoticeService {

  constructor(
    private webService:WebService
  ) { }

  getNotice(payload: any) {
    return this.webService.post('company/company-dashboard-expire-and-expiring-documents', payload);
  }
  singleViewImage(payload: any) {
    return this.webService.post('company/single-view-image', payload);
  }
}

import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LanguageTranslateService } from 'src/app/services/language-translate.service';
import * as Global from 'src/app/shared/globals';

@Component({
  selector: 'app-custom-table-filter',
  templateUrl: './custom-table-filter.component.html',
  styleUrls: ['./custom-table-filter.component.css']
})
export class CustomTableFilterComponent {
  Global = Global;
  @Output() onPageFilter: EventEmitter<any> = new EventEmitter<any>();
  TableOptionForm:FormGroup

  constructor(
    private formBuilder:FormBuilder
  ){
    this.TableOptionForm = formBuilder.group({
      length: [Global.DataTableLength, Validators.compose([])],
      searchkey: [null, Validators.compose([])],
    });
  }
  filterChanged() {
    // console.log('this.TableOptionForm.value : ', this.TableOptionForm.value);
    this.onPageFilter.emit(this.TableOptionForm.value.length)
  }
}

import { Injectable } from '@angular/core';
import { WebService } from './web.service';

@Injectable({
  providedIn: 'root'
})
export class TableFilterService {

  constructor(
    private webService: WebService
  ) { }

  getEmployeeMaster() {
    return this.webService.post('company/get-employee-master', {});
  }
}

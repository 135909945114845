import { Component, Input, OnInit } from '@angular/core';
import * as Global from 'src/app/shared/globals';

@Component({
  selector: 'app-print-payslip',
  templateUrl: './print-payslip.component.html',
  styleUrls: ['./print-payslip.component.css']
})
export class PrintPayslipComponent implements OnInit{
  Global = Global
  @Input() empData: any = null
  @Input() tempDetails: any = null
  @Input() wageMonth: any = null
  @Input() wageYear: any = null

  constructor(){
    console.log(this.empData,'emp data');
    
  }
  
  ngOnInit(): void {
    console.log(this.empData,'emp datass');
    
  }

  
}

<div class="app-content leave-cms">
    <app-menu-sidebar></app-menu-sidebar>

    <div class="app-header topfinance-nav">
        <ul>
            <li [class.active]="isActive('employee-attendance')" (click)="routerChange('employee-attendance')">{{translations?.employeeAttendance}}</li>
            <li [class.active]="isActive('attendance-summary')" (click)="routerChange('attendance-summary')">{{translations?.attendanceSummary}}</li>
            <li [class.active]="isActive('attendance-listing-console')" (click)="routerChange('attendance-listing-console')">{{translations?.attendanceListingConsole}}</li>
        </ul>
        
        <app-language></app-language>
        <app-profile></app-profile>
    </div>

  
    <router-outlet></router-outlet>

</div>
<ngx-spinner type="ball-scale-multiple"></ngx-spinner>

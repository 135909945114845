import { Component, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import PaginationOptions from 'src/app/models/PaginationOptions';
import { LanguageTranslateService } from 'src/app/services/language-translate.service';
import { RazorpayPaymentService } from 'src/app/services/razorpay-payment.service';
import { resetPaginationOption } from 'src/app/shared/globals';

@Component({
  selector: 'app-credit-purchase-history',
  templateUrl: './credit-purchase-history.component.html',
  styleUrls: ['./credit-purchase-history.component.css'],
})
export class CreditPurchaseHistoryComponent implements OnInit, OnDestroy {
  currentLanguage$?: Subscription;
  translations: any;
  paginationOptions: PaginationOptions = resetPaginationOption();
  paymentHistoryList: any[] = []
  constructor(
    private langService: LanguageTranslateService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private paymentService: RazorpayPaymentService
  ) { }

  ngOnInit() {
    this.currentLanguage$ = this.langService.currentLanguage$.subscribe(
      async () => {
        this.translations = await this.langService.loadTranslations(
          'home',
          'CreditPurchaseHistoryComponent'
        );
      }
    );
    this.fetchPaymentHistory();
  }

  async fetchPaymentHistory() {
    try {
      this.spinner.show();
      const res =
        await this.paymentService.fetchCreditPurchaseTransactionHistory({
          pageno: this.paginationOptions?.page,
          perpage: 10,
          orderby: 'desc'
        });
      if (res?.status !== 'success') throw res;
      this.paymentHistoryList = res?.payment_history?.docs;
      this.paginationOptions = {
        hasNextPage: res?.payment_history?.hasNextPage,
        hasPrevPage: res?.payment_history?.hasPrevPage,
        limit: res?.payment_history?.limit,
        nextPage: res?.payment_history?.nextPage,
        page: res?.payment_history?.page,
        pagingCounter: res?.payment_history?.pagingCounter,
        prevPage: res?.payment_history?.prevPage,
        totalDocs: res?.payment_history?.totalDocs,
        totalPages: res?.payment_history?.totalPages,
      };
      this.spinner.hide();
    } catch (err: any) {
      this.spinner.hide();
      this.toastr.error(err?.message || err || 'Something went wrong');
    }
  }
  async downloadPaymentHistory(historyId: string, date: string) {
    try {
      const fileName = `Payment-History-${moment(date).format('DD-MM-YYYY')}`
      await this.paymentService.downloadFile('get-payment-history', fileName, {
        pageno: this.paginationOptions?.page,
        checked_row_ids: JSON.stringify([historyId]),
        generate: "excel",
        row_checked_all: false,
        unchecked_row_ids: JSON.stringify([]),
      });
    } catch (err: any) {
      this.spinner.hide();
      this.toastr.error(err?.message || err || 'Something went wrong');
    }
  }

  ngOnDestroy(): void {
    this.currentLanguage$?.unsubscribe();
  }
}

import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AtsService } from 'src/app/services/ats.service';
import * as Global from 'src/app/shared/globals';

@Component({
  selector: 'app-recruitment-form',
  templateUrl: './recruitment-form.component.html',
  styleUrls: ['./recruitment-form.component.css']
})
export class RecruitmentFormComponent {
  requistionId:any;
  requistionData:any;
  singleTemplateData:any;
  employeeMaster!:any[];
  employeeDetails:any;
  recruitmentForm!: FormGroup;
  Global = Global;
  captchaResolved: boolean = false; 
  captchaToken: any = ''; 
  requistionUniqueId:any;
  requisitionList!:any[];
  companyData: any;
  isFormSubmit:any;


  constructor(
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private formBuilder:FormBuilder,
    private atsService: AtsService,
    private toastr: ToastrService,
    private router: Router
  ){
    this.recruitmentForm = this.formBuilder?.group({
      first_name: [null, Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z ]+$')])],
      last_name: [null, Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z ]+$')])],
      email: [null, Validators.compose([Validators.required])],
      phone: [null, Validators.compose([Validators.required,  Validators.pattern('^[0-9]*$'), Validators.minLength(10), Validators.maxLength(15) ])],
      cv: [null, Validators.compose([Validators.required])],
      image: [null, Validators.compose([])],
      captcha: [null, Validators.compose([Validators.required])],
      location: [null, Validators.compose([Validators.required])]

    })

    let r = this.router.url.split('/');
    console.log(r)
     const urlId= r[3]
     this.requistionUniqueId = decodeURIComponent(urlId);
    console.log(this.requistionUniqueId)
    this.atsService.checkRequistion({
      uniqueId: this.requistionUniqueId
    }).subscribe((res)=>{
      console.log(res)
      this.fetchRequistionList();
     
    })

  }

  ngOnInit(): void {
    // this.route.params.subscribe(params => {
    //   console.log(params)
    //   this.requistionId = params['id'] ?? null;
    //   console.log(this.requistionId,'ib acceptance-onboard-form')
    //   this.fetchMaster();
    //   this.fetchRequistionDetails();
    // })
    let comData:any=(localStorage.getItem('hrmlix-uae-companyuser-details'));
    this.companyData = JSON.parse(comData);
    console.log(this.companyData)

   this.fetchMaster()
   
   

    
  }

  fetchMaster(){
    return new Promise<any>((resolve, reject) => {
       this.spinner.show();
      this.atsService.getMasterList().subscribe((res: any) => {
        console.log(res, "master");
        if (res?.status == 200) {
          // this.departmentsMaster = [];
          // if (res?.data?.departments && Array.isArray(res?.data?.departments)) {
          //   res?.data?.departments.forEach((element: any) => {
          //         this.departmentsMaster.push({ id: element.id, description: `${element.description}`, master_id: element?.master_id, name:`${element.name}` })
          //     });
          // }
          this.employeeMaster = [];
          if (res?.data?.employees && Array.isArray(res?.data?.employees)) {
            res?.data?.employees.forEach((element: any) => {
              //  console.log(element)
                  this.employeeMaster.push({ id: element.id, description:`${element.emp_first_name +" "+ 
                    element.emp_last_name
                  }`, value:`${element.emp_first_name +" " + element.emp_last_name}` })
              });
          }
          // console.log(this.employeeMaster)
          // console.log(this.departmentsMaster)
          // this.departmentsMaster = res?.data?.departments ?? [];
          resolve(true);
          this.spinner.hide();
        } else {
          this.spinner.hide();
          this.toastr.error(res?.message);
          resolve(false);
        }
      })
    });
  }

  fetchRequistionDetails(){
    return new Promise<any>((resolve, reject) => {
      console.log(this.requistionId)
       this.spinner.show();
      this.atsService.getSingleRequistion(this.requistionId).subscribe((res: any) => {
        // console.log(res, "tttt");
        if (res?.message == 'Get single requistion data') {
          this.requistionData = res?.data?.listData ?? {};
          console.log(this.requistionData)
          
          resolve(true);
          this.spinner.hide();
        } else {
          this.spinner.hide();
          this.toastr.error(res?.message);
          resolve(false);
        }
      })
    });
  }

  fetchRequistionList() {
    return new Promise((resolve, reject) => {
      this.atsService.getRequistionList({}).subscribe((res: any) => {
        // console.log(res)
        const data = res?.data ?? {};
        // console.log(data)
        if (res?.status == 200) {
          this.requisitionList = data?.listData ?? [];
            // console.log(this.requisitionList)
          this.requisitionList.forEach((obj:any)=>{

           if(obj?.uniqueId === this.requistionUniqueId){
            console.log(obj.uniqueId)
            // console.log(this.requistionUniqueId)
            // console.log(obj?.id)
            return this.requistionId = obj?.id
           } 
          })
          console.log(this.requistionId)
          this.fetchRequistionDetails();
          this.spinner.hide();
         
          resolve(true);
        } else {
          this.toastr.error(res?.message);
          resolve(false);
        }
      })
    })
  }

  onFileUpload(event: any, formType: FormGroup, formControlName: string, fileType: string[]) {
    const maxSize = 5 * 1024 * 1024; // 5MB in bytes
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      if (fileType.includes(file.type)) {
        if (file.size <= maxSize) {
          formType.patchValue({
            [formControlName]: file,
          });
        } else {
          this.toastr.error("File size should not exceed 5 MB");
        }
      } else {
        if (fileType.includes('application/pdf')) {
          this.toastr.error("Accept only PNG/JPG/PDF type");
        } else {
          this.toastr.error("Accept only PNG/JPG type");
        }
      }
    } else {
      this.toastr.error("File is not found");
    }
  }
  

  onCaptchaResolved(captchaRes: any) {
    console.log(captchaRes)
    this.recruitmentForm.patchValue({
      captcha: captchaRes
    });  
  }

//   apply(event:any){
//     if (this.recruitmentForm.invalid) {
//       this.recruitmentForm.markAllAsTouched();
//       return;
//     }
//     console.log(this.recruitmentForm.value.cv)
//     const payload = {
//       fname: this.recruitmentForm.value.first_name,
//       lname: this.recruitmentForm.value.last_name,
//       email: this.recruitmentForm.value.email,
//       phone: this.recruitmentForm.value.phone,
//       requisition_id: this.requistionId,
//       resume: this.recruitmentForm.value.cv
      
//     }

//     this.atsService.applicantForm(payload).subscribe({next:(res) => {
//        console.log(res)   
//       if (res.status ==  201) {
//       this.toastr.success("Template Created Successfully");
//       // this.routeToPage();
         
//     }  else if (res.status == 'val_err') {
//       this.toastr.error(Global.showValidationMessage(res.val_msg));
//   } else {
//       this.toastr.error(res.message);
//   }
   
//   },
//   error: (err) => {
//     console.error('Error occurred:', err); // Handle the error response
     
//  },
//  complete: () => {
//   console.log('Request completed'); // Optional: Handle when the observable completes
// }
// })
//   }
apply(event: any) {
  if (this.recruitmentForm.invalid) {
    this.recruitmentForm.markAllAsTouched();
    return;
  }

  const formData = new FormData();
  formData.append('fname', this.recruitmentForm.value.first_name);
  formData.append('lname', this.recruitmentForm.value.last_name);
  formData.append('email', this.recruitmentForm.value.email);
  formData.append('phone', this.recruitmentForm.value.phone);
  formData.append('requisition_id', this.requistionId.toString());
  formData.append('location', this.recruitmentForm.value.location);
  // Add the file directly from the control value
  formData.append('resume', this.recruitmentForm.value.cv);
  formData.append('image', this.recruitmentForm.value.image);
  formData.append('captcha', this.recruitmentForm.value.captcha);

  this.atsService.applicantForm(formData).subscribe({
    next: (res) => {
      console.log(res);
      if (res.status === "success") {
        this.toastr.success("Form Submitted Successfully");
        this.isFormSubmit = 'yes';
        // Optional: Redirect to another page
      } else if (res.status == 'val_err') {
        this.toastr.error(Global.showValidationMessage(res.val_msg));
      } else {
        this.toastr.error(res.message);
      }
    },
    error: (err) => {
      console.error('Error occurred:', err); // Handle the error response
    },
    complete: () => {
      console.log('Request completed'); // Optional: Handle when the observable completes
    }
  });
}
}

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { LanguageTranslateService } from 'src/app/services/language-translate.service';

@Component({
  selector: 'app-view-image-dialog',
  templateUrl: './view-image-dialog.component.html',
  styleUrls: ['./view-image-dialog.component.css']
})
export class ViewImageDialogComponent {
  lang:any = 'en'

  imagePath:any;
  currentLanguage$?:Subscription;
  translations:any;
  constructor(
    private dialogRef:MatDialogRef<ViewImageDialogComponent>,
    private langService:LanguageTranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ){
   this.imagePath = data?.img
  }
  ngOnInit(): void {
    this.currentLanguage$ = this.langService.currentLanguage$.subscribe(async () => {
      this.lang = this.langService.getLangLocalStorage();
       return new Promise<boolean>(async (resolve, reject) => {
         await this.langService.loadTranslations('layout', 'ViewImageDialogComponent')?.then((res: any) => {
           this.translations = res;
          
           resolve(true);
         }).catch((err: any) => {
           console.error(`Failed to load translations`, err);
           reject(false);
         });
       })
     });
  }
  closeDialog(){
    this.dialogRef.close();
  }
}

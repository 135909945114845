import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LanguageTranslateService } from 'src/app/services/language-translate.service';
import * as Global from 'src/app/shared/globals';

@Component({
  selector: 'app-salary-revision',
  templateUrl: './salary-revision.component.html',
  styleUrls: ['./salary-revision.component.css']
})
export class SalaryRevisionComponent implements OnInit {
  
  lang:any="en"
  Global = Global;
  currentLanguage$?: Subscription;
  
  translations: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private langService: LanguageTranslateService,
  ) {
    this.route.paramMap.subscribe((params: any) => {
      this.lang = params.get('lang');
    });
  }

ngOnInit(): void {
  this.currentLanguage$ = this.langService.currentLanguage$.subscribe(async () => {
    this.lang = this.langService.getLangLocalStorage();
          return new Promise<boolean>(async (resolve, reject) => {
            await this.langService.loadTranslations('salary-revision', 'SalaryRevisionComponent')?.then((res: any) => {
              this.translations = res;
              resolve(true);
            }).catch((err: any) => {
              console.error(`Failed to load translations`, err);
              reject(false);
            });
          })
  });
}
  routerChange(routeString: string) {
    this.router.navigate([this.lang, 'salary-revision', routeString]);
  }
  isActive(routeString: string): boolean {
    if (this.router.url.includes(routeString)) {
      return true;
    }
    return false;
  }
}

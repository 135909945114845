<div class="modalmain">
    
       

          

                <div class="p-15 modal-header bg-light border-bottom">
                    <div class="modal-text">
                       <h6>{{announcement_title}}</h6>
                    </div>
                    <button class="btn-close btn ms-4 ms-auto" (click)="closeDialog()"><i class="bi bi-x-lg"></i></button>
                </div>
                <div class="modal-body">

                    <div class="table-responsive table-left bg-gay">
                        <table class="table  table-bordered shift-allowance">
                            <tbody>
                              <tr>
                                <td style="width: 20%;">
                                  <label class="ms_container">
                                      <span>{{translations?.slNo}}</span>
                                      <!-- <input type="checkbox">
                                      <span class="checkmark"></span> -->
                                  </label>
                                </td>
                                <td style="width: 50%;">{{translations?.employeeName}}</td>
                                <td>{{translations?.comments}}</td>
                                
                              </tr>
                              <tr *ngFor="let item of announcement_comment_data; let i = index">
                                <td>
                                  <div class="w-100 d-flex align-items-center">
                                      <label class="ms_container">
                                          <!-- <input type="checkbox"> -->
                                          <span>{{i+1}}</span>
                                      </label>
                                      
                                  </div>
                                </td>
                                <td> 
                                  <!-- <div class="text-center flip-element">
                                    <ng-container *ngIf="lang == 'ar'; else elseEngName">
                                        <p class="">{{item?.emp_first_name_uae ?? 'N/A'}} {{item?.emp_last_name_uae?? 'N/A'}}</p>
                                      </ng-container>
                                      <ng-template #elseEngName>
                                          <p class="">{{item?.emp_first_name ?? 'N/A'}} {{item?.emp_last_name ?? 'N/A'}}</p>
                                      </ng-template>
                                </div>    -->
                                {{(lang === 'ar')? item.emp_first_name_uae : item.emp_first_name}}  {{(lang === 'ar')? item.emp_last_name_uae : item.emp_last_name}} 
                                </td>
                                <td> {{item.reply_description}} </td>
                                <tr *ngIf="announcement_comment_data?.length == 0">
                                  <td colspan="12" class="text-center">{{translations?.noRecordsFound}}</td>
                              </tr>
                            
                              
                            </tbody>
                        </table>
                    </div>

                    <app-custom-pagination [rows]="announcement_comment_data" [paginationOptions]="paginationOptions" (onPageClicked)="fetchAnnouncementComment()"></app-custom-pagination>
                    
                </div>

            

       

  </div>
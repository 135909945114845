import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RecruitmentFormComponent } from './recruitment-form.component';

const routes: Routes = [
  { path: '', component: RecruitmentFormComponent },

//   {
//   path:'', children:[
//   {path:'',redirectTo:'recruit',pathMatch:'full'},
//   { path: 'recruit',pathMatch:"full", component: RecruitmentFormComponent },
//   ]
// }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RecuitmentFormRoutingModule { }

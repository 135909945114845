export const environment = {
    production: false,
    apiUrl: 'https://uaedevcombackend.hrmlix.com',
    BASEHREF: 'api/company-backend-service',
    ASSET_URL: '',
    APPNAME: 'The Seamless HR Solution',
    DATATABLELENGTH: 20,
    DATATABLELENGTHCHANGEMENU: [1, 10, 20, 50, 75, 100],
    USERAUTH: '',
    GOOGLEMAPKEY: 'AIzaSyCJh98m3Q4lDtUrn21uG8p-bSTc0pZvxOI',
    RAZORPAYKEY: 'rzp_test_MhGoAHitNBkeU0',
    RAZORPAYCOMPANYNAME: 'HRMlix',
    RAZORPAYCOMPANYLOGO: 'https://cdn3.iconfinder.com/data/icons/inficons-currency-set/512/rupee-512.png',
    RAZORPAYCURRENCY: 'INR',
    apiAtsUrl: 'https://devatsbackend.hrmlix.com',
    BASEHREF_ATS: 'api/v1/ats-service',
    
  };
  
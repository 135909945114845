


    
        <div class="p-15 border-bottom modal-header bg-light">
            <div class="modal-text">
                <h3 class="derktext left-line mb-0 static-text"> {{translations?.updatePassword}}</h3>
            </div>
            <button class="btn-close btn ms-4 ms-auto"><i class="bi bi-x-lg" (click)="closeDialog()"></i></button>
        </div>
   
        <form [formGroup]="passwordForm">
          
                <div class="p-15 pt-4 modal-body s">
                    <div class="risection">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="mb-3 form-group">
                                    <label for="" class="form-label">{{translations?.oldPassword}} <span class="text-danger">*</span></label>
                                    <input type="password" placeholder="" class="form-control" formControlName="old_password">
                                    <p class="error-element" *ngIf="passwordForm.controls['old_password'].hasError('required') && (passwordForm.controls['old_password'].dirty || passwordForm.controls['old_password'].touched)">{{translations?.thisFieldIsRequired}}</p>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="mb-3 form-group amount">
                                    <label for="" class="form-label">{{translations?.newPassword}} <span class="text-danger">*</span></label>
                                <input type="password" placeholder="" class="form-control" formControlName="new_password">
                                <p class="error-element" *ngIf="passwordForm.controls['new_password'].hasError('required') && (passwordForm.controls['new_password'].dirty || passwordForm.controls['new_password'].touched)">{{translations?.thisFieldIsRequired}}</p>
                                <!-- <p class="error-element" *ngIf="passwordForm.controls['new_password'].hasError('pattern') && (passwordForm.controls['new_password'].dirty || passwordForm.controls['new_password'].touched)">{{translations?.thisFieldIsRequired}}</p> -->
                                <p class="error-element static-text" *ngIf="passwordForm.controls['new_password'].hasError('maxlength') || passwordForm.controls['new_password'].hasError('minlength') && (passwordForm.controls['new_password'].dirty)">{{translations?.validPassword}}</p>
                                </div>
                            </div>

                            <div class="col-md-12">
                                <div class="mb-3 form-group amount">
                                    <label for="" class="form-label">{{translations?.confirmPassword}} <span class="text-danger">*</span></label>
                                <input type="password" placeholder="" class="form-control" formControlName="confirm_password">
                                <p class="error-element" *ngIf="passwordForm.controls['confirm_password'].hasError('required') && (passwordForm.controls['confirm_password'].dirty || passwordForm.controls['confirm_password'].touched)">{{translations?.thisFieldIsRequired}}</p>
                                <!-- <p class="error-element" *ngIf="passwordForm.controls['confirm_password'].hasError('pattern') && (passwordForm.controls['confirm_password'].dirty || passwordForm.controls['confirm_password'].touched)">{{translations?.passwordFormat}}</p> -->
                                <p class="error-element" *ngIf="passwordForm.controls['confirm_password'].hasError('mismatch') && (passwordForm.controls['confirm_password'].dirty || passwordForm.controls['confirm_password'].touched)">{{translations?.passwordsNotMatch}}</p>
                                </div>
                            </div>

                         </div>
                      </div>

                    </div>
               

                <div class="modal-footer p-15 py-2 border-top">
                    <button class="btn button-solide me-13"  
                     (click)="updatePassword($event)"><span> {{translations?.update}} </span></button>
                    <button class="btn button-solide gray-btn" (click)="closeDialog()"> {{translations?.cancel}}</button>
                </div>
          
        </form>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LanguageTranslateService } from 'src/app/services/language-translate.service';
import * as Global from 'src/app/shared/globals';

@Component({
  selector: 'app-payrolls',
  templateUrl: './payrolls.component.html',
  styleUrls: ['./payrolls.component.css']
})
export class PayrollsComponent implements OnInit {
lang:any="en"
  Global = Global;
  currentLanguage$?: Subscription;
  
  translations: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private langService: LanguageTranslateService,
  ) {
    this.route.paramMap.subscribe((params: any) => {
      this.lang = params.get('lang');
    });
  }
  ngOnInit(): void {
    this.currentLanguage$ = this.langService.currentLanguage$.subscribe(async () => {
    this.lang = this.langService.getLangLocalStorage();
      return new Promise<boolean>(async (resolve, reject) => {
        await this.langService.loadTranslations('payrolls', 'PayrollsComponent')?.then((res: any) => {
          this.translations = res;
          resolve(true);
        }).catch((err: any) => {
          console.error(`Failed to load translations`, err);
          reject(false);
        });
      })
    });
  }
  ngOnDestroy() {
    this.currentLanguage$?.unsubscribe();
  }
  routerChange(routeString: string) {
    this.router.navigate([this.lang, 'payrolls', routeString]);
  }
  isActive(routeString: string): boolean {
    if (this.router.url.includes(routeString)) {
      return true;
    }
    return false;
  }
}

import {Component,ElementRef,OnInit} from '@angular/core';
import {FormBuilder,FormControl,FormGroup,Validators,} from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription, debounceTime } from 'rxjs';
import PaginationOptions from 'src/app/models/PaginationOptions';
import { AnnouncementService } from 'src/app/services/announcement.service';
import { LanguageTranslateService } from 'src/app/services/language-translate.service';

import * as Global from 'src/app/shared/globals';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-announcement-list',
  templateUrl: './announcement-list.component.html',
  styleUrls: ['./announcement-list.component.css'],
})
export class AnnouncementListComponent implements OnInit {
  // @ViewChild('myInput') myInput!: QueryList<ElementRef>;
  Global = Global;
  announcementList: any[]=[];
  announcementListReply: any;
  replyForm: FormGroup;
  lang: any = 'en';
  reply_length: any;
  currentLanguage$?: Subscription;
  paginationOptions: PaginationOptions = Global.resetPaginationOption();
  searchkey = new FormControl('');
  translations: any;

  constructor(
    private announceServ: AnnouncementService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private langService: LanguageTranslateService,
    private router: Router
  ) {
    this.getAnnouncement(this.paginationOptions.page, this.searchkey.value);

    this.replyForm = this.formBuilder.group({
      reply_description: [null, Validators.compose([])],
    });
  }

  ngOnInit(): void {
    this.currentLanguage$ = this.langService.currentLanguage$.subscribe(
      async () => {
        this.lang = this.langService.getLangLocalStorage();
        return new Promise<boolean>(async (resolve, reject) => {
          await this.langService
            .loadTranslations('home', 'AnnouncementListComponent')
            ?.then((res: any) => {
              this.translations = res;
              resolve(true);
            })
            .catch((err: any) => {
              console.error(`Failed to load translations`, err);
              reject(false);
            });
        });
      }
    );

    this.searchkey.valueChanges.pipe(debounceTime(700)).subscribe((value) => {
      this.getAnnouncement(1, value);
    });
  }
 
  routeToannounce() {
    this.lang = this.langService.getLangLocalStorage() ?? 'en';
    this.router.navigate([this.lang, 'announcement']);
  }

  public focusOnInput(index: any) {
    // Use Renderer2 service to focus on the input field
    // console.log(this.myInput);
    // this.renderer.selectRootElement(this.myInput).focus();
    
    setTimeout(() => {
      const element = document.getElementById(`replyInput_${index}`);
    
      if (element) {
        
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        element.focus();
      }
    }, 300);
  }

  getAnnouncement(page: number, searchKey: any) {
    // if (searchKey.length === 0 || searchKey.length >= 3) {
    const date = new Date();
    return new Promise((resolve, reject) => {
      this.spinner.show();
      let payload:any = {
        pageno: page ?? 1,
        searchkey: searchKey,
        status:'published'
      }
      this.announceServ
        .getAnnouncements(payload)
        .then((res: any) => {
          if (res?.status === 'success') {
            const data = res?.post_data ?? {};
            this.announcementList = res?.post_data?.docs ?? [];
            this.paginationOptions = {
              page: data?.page,
              limit: data?.limit,
              hasNextPage: data?.hasNextPage,
              hasPrevPage: data?.hasPrevPage,
              nextPage: data?.nextPage,
              prevPage: data?.prevPage,
              totalDocs: data?.totalDocs,
              totalPages: data?.totalPages,
              pagingCounter: data?.pagingCounter,
            };
          }
          this.spinner.hide();
          resolve(true);
        })
        .catch((err: any) => {
          this.spinner.hide();
          reject(err);
        });
    });
    // } else {
    // this.announcementList = [];
    // return Promise.resolve(false);
    // }
  }

  showInput: boolean = false;
  getReplyId: any = '';
  viewMoreReply: any;
  getReplyIndex: any = '';
  getAnnouncementReply(id: any, ind: any) {
    const date = new Date();
    this.announcementList.forEach((item: any, i: any) => {
      // if (i !== ind) {
      //   item.showInput = false;
      //   item.showInputButton = false;
      // }
    });
    this.announcementList[ind].showInput =
      !this.announcementList[ind].showInput;
    // this.announcementList[ind].showInputButton = true

    return new Promise((resolve, reject) => {
      this.announceServ
        .getReplyAnnouncements({parent_id: id })
        .then((res: any) => {
          if (res?.status === 'success') {
            this.announcementList[ind].replies = res?.data ?? [];
          }
          if (res?.data?.totalDocs > 2) {
            // this.announcementList[ind].replies.viewReply = res?.data?.totalDocs - replyLimit;
          }
          resolve(true);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
  }
  // getAnnouncementReplypage(id:any, ind:any, ){

  //   const date = new Date();

  //   return new Promise((resolve,reject)=>{
  //     this.announceServ.getReplyAnnouncements({pageno:1,  parent_id:id}).then((res:any)=>{
  //        this.announcementList[ind].replies =  res?.data?.docs;
  //        if (  res?.data?.totalDocs > 2) {
  //         console.log('log totalDocs');

  //         // this.announcementList[ind].replies.viewReply = res?.data?.totalDocs - replyLimit;
  //        }
  //        else {
  //         this.announcementList[ind].replies.noReply = 'no';
  //        }
  //       //  this.viewMoreReply = res?.data?.totalDocs;
  //       console.log(this.announcementList,'ann list');

  //         resolve(true);
  //       }).catch((err:any)=>{
  //       reject(err)
  //     })
  //   })
  // }

  getReply(id: any, index: any) {
    return new Promise((resolve, reject) => {
      this.announceServ
        .getReplyAnnouncements({
          pageno: this.paginationOptions.page ?? 1,
          parent_id: id,
        })
        .then((res: any) => {
          if (res?.status === 'success') {
            this.announcementList[index].replies = res?.data ?? [];
           if (!this.announcementList[index].showInput) {
            
            this.announcementList[index].showInput =
            true;
            this.focusOnInput(index);
           }
          }
          resolve(true);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
  }
  createReply(id: any, index: any) {
    const date = new Date();
    this.getReplyId = id;
    this.getReplyIndex = index;
    return new Promise((resolve, reject) => {
      this.announceServ
        .createReply({
          parent_id: id,
          reply_description: this.replyForm.value.reply_description,
        })
        .then((res: any) => {
          this.replyForm.reset();
         
       
          this.getReply(this.getReplyId, this.getReplyIndex);
          //  this.getAnnouncement();
          resolve(true);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
  }
  routeToanncDetails(id:any){
    this.router.navigate([this.lang,'announcement-detail',id])
  }
  
  ngOnDestroy(): void {
    this.currentLanguage$?.unsubscribe();
  }
}

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageService } from '../services/storage.service';
import { AuthService } from 'src/app/services/auth.service';
@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  constructor(private storage:StorageService, private authService:AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    const token = this.storage.getToken()

    if (token) {
      request = request.clone({
        setHeaders:{
          'x-access-token':token
        }
      })
    }
    return next.handle(request);
  }
}

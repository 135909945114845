<div class="app-content leave-cms">
    <app-menu-sidebar></app-menu-sidebar>

    <div class="app-header topfinance-nav">
        <ul>
            <li [class.active]="isActive('leave-approval')" (click)="routerChange('leave-approval')">{{translations?.leaveApproval}}</li>
            <li [class.active]="isActive('reimbursement')" (click)="routerChange('reimbursement')">{{translations?.reimbursement}}</li>
            <li [class.active]="isActive('extra-earning')" (click)="routerChange('extra-earning')">{{translations?.extraEarning}}</li>
            <li [class.active]="isActive('advance-request')" (click)="routerChange('advance-request')">{{translations?.advanceRequest}}</li>
            <li [class.active]="isActive('work-from-home')" (click)="routerChange('work-from-home')">{{translations?.workFromHome}}</li>
            <li [class.active]="isActive('regularize-attendance')" (click)="routerChange('regularize-attendance')">{{translations?.regularizeAttendance}}</li>
          </ul>

          <app-language></app-language>
          <app-profile></app-profile>
    </div>
  
    <router-outlet></router-outlet>

</div>
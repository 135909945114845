<div class="app-content leave-cms">
    <app-menu-sidebar></app-menu-sidebar>

    <div class="app-header topfinance-nav">
        <ul>
            <li class="static-text" [class.active]="isActive('shift-list')" (click)="routerChange('shift-list')">{{translations?.shiftList}}</li>
            <li class="static-text" [class.active]="isActive('shift-allowance')" (click)="routerChange('shift-allowance')">{{translations?.shiftAllowance}}</li>
            <li class="static-text" [class.active]="isActive('allocate-shift')" (click)="routerChange('allocate-shift')">{{translations?.allocateShift}}</li>
            <li class="static-text" [class.active]="isActive('shift-batch')" (click)="routerChange('shift-batch')">{{translations?.shiftBatch}}</li>
            <li class="static-text" [class.active]="isActive('shift-earning-report')" (click)="routerChange('shift-earning-report')">{{translations?.shiftEarningReport}}</li>
            <li class="static-text" [class.active]="isActive('shift-duty-report')" (click)="routerChange('shift-duty-report')">{{translations?.shiftDutyReport}}</li>
        </ul>
        
        <app-language></app-language>
        <app-profile></app-profile>
    </div>

  
    <router-outlet></router-outlet>

</div>
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { RazorpayPaymentService } from 'src/app/services/razorpay-payment.service';
import { RazorpayDoc, showServerErrorMessage } from 'src/app/shared/globals';
import { Subscription } from 'rxjs';
import { LanguageTranslateService } from 'src/app/services/language-translate.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-purchase-credit-dialog',
  templateUrl: './purchase-credit-dialog.component.html',
  styleUrls: ['./purchase-credit-dialog.component.css'],
})
export class PurchaseCreditDialogComponent implements OnInit, OnDestroy {
  currentLanguage$?: Subscription;
  translations: any;
  creditAmountPerToken: number = 0;
  vatPercentage: number = 0;
  creditForm: FormGroup = new FormGroup({
    credit_amount: new FormControl(null, [
      Validators.required,
      Validators.pattern('^[0-9]*$'),
    ]),
    credit_coupon: new FormControl(null, [
      Validators.required,
      Validators.pattern('^[0-9]*$'),
    ]),
    coupon_code: new FormControl(''),
    payable_amount: new FormControl(0, Validators.required),
  });
  appliedCoupon: any;
  // isCouponCodeValid:boolean = false

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private langService: LanguageTranslateService,
    public dialogRef: MatDialogRef<PurchaseCreditDialogComponent>,
    private paymentService: RazorpayPaymentService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
  ) {
    this.creditAmountPerToken = data?.creditAmountPerToken || 0;
    this.vatPercentage = data?.vatPercentage || 0;
  }

  ngOnInit() {
    this.currentLanguage$ = this.langService.currentLanguage$.subscribe(
      async () => {
        this.translations = await this.langService.loadTranslations(
          'home',
          'PurchaseCreditDialogComponent'
        );
      }
    );
  }

  async applyCouponCode() {
    try {
      if (this.creditForm.invalid) return;
      const res = await this.paymentService.verifyCreditPurchaseCoupon({
        coupon_code: this.creditForm.get('coupon_code')?.value || '',
        purchase_amount: this.creditForm.get('payable_amount')?.value || '',
      });

      if (res?.status !== 'success') throw res;
      this.appliedCoupon = res?.coupon_data ?? null;
    } catch (err: any) {
      this.removeCouponCode();
      this.toastr.error(err || err?.message || 'Something went wrong');
    }
  }

  calculatePayableTotal(type: any) {
    switch (type) {
      case 'credit_amount':
        if (this.creditForm.value.credit_amount) {
          let credit_amount: any = parseFloat(
            this.creditForm.value.credit_amount
          );
          let payable_amount: any = parseFloat(credit_amount);

          payable_amount +=
            (this.vatPercentage / 100) * parseFloat(payable_amount);

          this.creditForm.patchValue({
            credit_coupon: credit_amount / this.creditAmountPerToken,
            payable_amount: payable_amount,
          });
        }
        break;

      case 'credit_coupon':
        if (this.creditForm.value.credit_coupon) {
          let credit_coupon = parseFloat(this.creditForm.value.credit_coupon);
          let payable_amount: any = credit_coupon * this.creditAmountPerToken;

          payable_amount +=
            (this.vatPercentage / 100) * parseFloat(payable_amount);

          this.creditForm.patchValue({
            credit_amount: (credit_coupon * this.creditAmountPerToken).toFixed(
              2
            ),
            payable_amount: payable_amount,
          });
        }
        break;

      default:
        this.toastr.error('Unsupported Type');
        break;
    }
  }

  async purchaseCredit() {
    try {
      if (this.creditForm.invalid) return;
      this.spinner.show();
      const res = await this.paymentService.getCreditPurchaseOrderID({
        coupon_amount: this.creditForm.get('payable_amount')?.value,
      });
      if (res.status !== 'success') throw res;
      if (!res?.message?.id) throw 'The OrderID not generated';
      this.payWithRazorPay(res?.message);
      this.spinner.hide();
    } catch (err: any) {
      this.spinner.hide();
      this.toastr.error(err?.message || err || 'Something went wrong');
    }
  }

  payWithRazorPay(document: any) {
    const options: any = {
      key: RazorpayDoc.key,
      amount: document?.amount ?? 0,
      currency: RazorpayDoc.currency,
      name: RazorpayDoc.company_name,
      description: 'Payment for Credit Token',
      image: RazorpayDoc.company_logo,
      order_id: document.id,
      modal: {
        escape: false,
      },
      notes: {},
      theme: {
        color: RazorpayDoc.theme.color,
      },
    };

    options.handler = (response: any, error: any) => {
      options.response = response;
      rzp.close();
      rzp = null;
      this.verifyRazorPayment(response);
    };

    options.modal.ondismiss = () => {
      rzp.close();
      rzp = null;
      Swal.fire(
        this.translations?.cancelled,
        this.translations?.transactionCancelled,
        'error'
      ).finally(() => this.dialogRef.close(true));
    };

    let rzp = this.paymentService.createRazorpayInstance(options);
    rzp.open();
  }

  async verifyRazorPayment(response: any) {
    try {
      let creditqty = 0;
      if (this.creditForm.value.credit_coupon)
        creditqty += parseInt(this.creditForm.value.credit_coupon);
      if (this.appliedCoupon?.coupon_amount)
        creditqty += parseInt(this.appliedCoupon?.coupon_amount);

      this.spinner.show();
      const res = await this.paymentService.verifyCreditPurchasePayment({
        razorpay_order_id: response?.razorpay_order_id ?? '',
        razorpay_payment_id: response?.razorpay_payment_id ?? '',
        razorpay_signature: response?.razorpay_signature ?? '',
        credit_amount: this.creditForm.value.credit_amount ?? 0,
        credit_qty: creditqty,
        coupon_code: this.appliedCoupon?.coupon_code ?? '',
      });

      this.spinner.hide();
      if (res?.status == 'success') {
        Swal.fire(this.translations?.success, res.message, 'success').finally(
          () => this.dialogRef.close(true)
        );
      } else {
        Swal.fire(this.translations?.failed, res.message, 'error').finally(() =>
          this.dialogRef.close(true)
        );
      }
    } catch (err: any) {
      this.spinner.hide();
      this.toastr.error(showServerErrorMessage(err));
    }
  }

  removeCouponCode() {
    this.appliedCoupon = null;
    this.creditForm.patchValue({
      coupon_code: null,
    });
  }

  ngOnDestroy(): void {
    this.currentLanguage$?.unsubscribe();
  }
}

import {Component,Input,OnInit,ViewChild,ViewEncapsulation} from '@angular/core';
import {FormBuilder,FormControl,FormGroup,Validators,FormArray,AbstractControl,} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AppComponent } from 'src/app/app.component';
import * as Global from 'src/app/shared/globals';
import { AuthService } from 'src/app/services/auth.service';
import { EmployeeProfileService } from 'src/app/services/employee-profile.service';
import { DatePipe } from '@angular/common';
// import { BankDetailsComponent } from '../../employee/profile/bank-details/bank-details.component';
import { BankDetailsComponent } from '../profile/components/bank-details/bank-details.component';
// import { AddressComponent } from '../../employee/profile/address/address.component';
import { AddressComponent } from '../profile/components/address/address.component';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { LanguageTranslateService } from 'src/app/services/language-translate.service';
@Component({
  selector: 'app-invite-employee',
  templateUrl: './invite-employee.component.html',
  styleUrls: ['./invite-employee.component.css'],
})
export class InviteEmployeeComponent implements OnInit {
  Global = Global;
  operation: any;
  lang:any='en';
  employee_details: any;
  address_details: any;
  bank_details: any;
  other_details: any;
  education_details: any;
  isPrev: boolean = false;
  isFormPending: boolean = true;
  isLoading: boolean = true;
  translations:any 
  education_filename:string= '';
  accident_filename:string= '';
  training_filname:any='';
  extraCurrCulum_filename:string= '';

  formType:
  'personal_details'| 'address_details'| 'bank_details'
 | 'pf_esi_data' | 'educational'| 'accident'| 'training'
 | 'extra_curricular' = 'personal_details';

 invitation_id: any;
 cancel_cheque_fileName:string='';

 education_id: any = '';
 accident_id: any = '';
 training_id: any = '';
 extracurriculum_id: any = '';

 employeeDetailsForm!: FormGroup;
 employeeAddressForm!: FormGroup;
 employeeBankForm!: FormGroup;
 employeeEducationForm!: FormGroup;
 employeeAccidentForm!: FormGroup;
 employeeTrainingForm!: FormGroup;
 employeeExtraCurriculumForm!: FormGroup;
 emirates_filname:string='';
 passport_filname:string='';
 additionalID_filname:string='';
 profile_filname:string='';
 yesNoMaster!: any[];
 sexMaster: any[] = Global.getGenderMaster();
 bloodGroupMaster: any[] = Global.bloodGroupMaster;
 maritalStatusMaster: any[] = Global.maritalStatusMaster;
 religionMaster: any[] = Global.religionMaster;
 hodMaster: any[] = [];
 addressProofMaster!: any[];
 accountTypeMaster: any[] = [];
 isLinkValid: boolean = false;
 employmentForm!: FormGroup;
 dispensaryForm!:FormGroup;
 dispensaryMaster: any[] = [];
 relationMaster: any[] = Global.relationMaster;
 currentLanguage$?: Subscription;

 @Input() isInvite: boolean = false;


 constructor(
  public formBuilder: FormBuilder,
  private titleService: Title,
  private toastr: ToastrService,
  private langService:LanguageTranslateService,
  private router: Router,
  // private companyuserService: CompanyuserService,
  private empService:EmployeeProfileService,
  private spinner: NgxSpinnerService,
  private activatedRoute: ActivatedRoute,
  private datePipe: DatePipe,
  public AppComponent: AppComponent
) {
  this.isLoading = true;

  this.invitation_id = activatedRoute.snapshot.queryParams['id'];

  this.employeeDetailsForm = formBuilder.group({
    emp_hod: [null, Validators.compose([Validators.required])],
    gross_salary: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern("^[0-9]*$")])],
    emp_first_name: [null, Validators.compose([Validators.required, Validators.pattern(/^[^0-9]+$/)])],
    emp_first_name_uae: [null, Validators.compose([Validators.required, Validators.pattern(/^[^0-9]+$/)])],
    emp_last_name: [null, Validators.compose([Validators.required, Validators.pattern(/^[^0-9]+$/)])],
    emp_last_name_uae: [null, Validators.compose([Validators.required, Validators.pattern(/^[^0-9]+$/)])],
    emp_father_name: [null, Validators.compose([Validators.pattern(/^[^0-9]+$/)])],
    emp_mother_name: [null, Validators.compose([Validators.pattern(/^[^0-9]+$/)])],
    official_email_id: [null, Validators.compose([Validators.email])],
    email_id: [null, Validators.compose([Validators.email, Validators.required])],
    official_mobile_no: [null, Validators.compose([ Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10)])],
    mobile_no: [null, Validators.compose([Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10)])],
    alternate_mob_no: [null, Validators.compose([Validators.minLength(10), Validators.maxLength(10)])],
    emp_dob: [null, Validators.compose([Validators.required])],
    sex: [null, Validators.compose([Validators.required])],

    passport_no: [null, Validators.compose([])],
    emp_passport_image: [null, Validators.compose([])],
    emp_passport_image_file: [null, Validators.compose([])],
    passport_val_form: [null, Validators.compose([])],
    passport_val_to: [null, Validators.compose([])],
    nationality: [null, Validators.compose([Validators.pattern(/^[^0-9]+$/), Validators.required])],
    blood_group: [null, Validators.compose([])],
    physical_disability: [null, Validators.compose([])],
    marital_status: [null, Validators.compose([])],
    marriage_date: [null, Validators.compose([])],
    emergency_contact_no: [null, Validators.compose([Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10)])],
    emergency_contact_name: [null, Validators.compose([Validators.pattern(/^[^0-9]+$/)])],
    domicile: [null, Validators.compose([Validators.pattern(/^[^0-9]+$/)])],
    height: [null, Validators.compose([])],
    religion: [null, Validators.compose([])],
    additional_id_image: [null, Validators.compose([])],
    additional_id_image_source: [null, Validators.compose([])],
    profile_image: [null, Validators.compose([])],
    profile_image_file: [null, Validators.compose([])],
    emirates_id: [null, Validators.compose([Validators.required,Validators.pattern("^[0-9]*$"),Validators.minLength(15)])],
    emirates_valid_to: [null, Validators.compose([Validators.required])],
    emirates_image: [null, Validators.compose([])],
    emirates_image_file: [null, Validators.compose([])],
    linked_uae_pass: ['yes', Validators.compose([Validators.required])],
    uae_pass_valid_to: [null, Validators.compose([])],
  });
  this.employeeAddressForm = formBuilder.group({
    resident_no: [null, Validators.compose([])],
    residential_name: [null, Validators.compose([])],
    road: [null, Validators.compose([])],
    locality: [null, Validators.compose([])],
    city: [null, Validators.compose([])],
    district: [null, Validators.compose([])],
    state: [null, Validators.compose([])],
    pincode: [
      null,
      Validators.compose([
        ,
        Validators.pattern('^[0-9]*$'),
        Validators.minLength(6),
        Validators.maxLength(6),
      ]),
    ],
    country: [null, Validators.compose([])],
    diff_current_add: [null, Validators.compose([])],
    curr_resident_no: [null, Validators.compose([])],
    curr_residential_name: [null, Validators.compose([])],
    curr_road: [null, Validators.compose([])],
    curr_locality: [null, Validators.compose([])],
    curr_city: [null, Validators.compose([])],
    curr_district: [null, Validators.compose([])],
    curr_state: [null, Validators.compose([])],
    curr_pincode: [
      null,
      Validators.compose([
        Validators.pattern('^[0-9]*$'),
        Validators.minLength(6),
        Validators.maxLength(6),
      ]),
    ],
    curr_country: [null, Validators.compose([])],
  });
  this.employeeBankForm = formBuilder.group({
            name_on_passbook: [null, Validators.compose([])],
            bank_name: [null, Validators.compose([Validators.required, Validators.pattern(/^[^0-9]+$/)])],
            branch_name: [null, Validators.compose([Validators.pattern(/^[^0-9]+$/)])],
            branch_address: [null, Validators.compose([])],
            branch_code: [null, Validators.compose([])],
            //   branch_pin: [null, Validators.compose([])],
            account_no: [null, Validators.compose([Validators.required, Validators.pattern("^[0-9]*$")])],
            account_no_confirmation: [null, Validators.compose([Validators.required, Validators.pattern("^[0-9]*$")])],
            account_type: [null, Validators.compose([])],
            //   ifsc_code: [null,Validators.required, Validators.compose([])],
            routing_no: [null, Validators.compose([])],
            iban: [null, Validators.compose([])],
            swift_code: [null, Validators.compose([])],
            micr_no: [null, Validators.compose([])],
            cancel_cheque: [null, Validators.compose([])],
            cancel_cheque_file: [null, Validators.compose([])],
  });
  this.employmentForm = formBuilder.group({
    // pre_er_pf: [null, Validators.compose([])],
   
 
    // esic_family_details: this.formBuilder.array([this.initFormRows('esic_family_details')]),
    // pf_nominee_details: this.formBuilder.array([this.initFormRows('pf_nominee_details')]),

  
  });
  this.dispensaryForm = formBuilder.group({
    dispensary_name: [null, Validators.compose([Validators.required])],
  });
  this.employeeEducationForm = formBuilder.group({
    institute: [null, Validators.compose([Validators.required])],
    university: [null, Validators.compose([Validators.required])],
    stream: [null, Validators.compose([Validators.required])],
    level: [null, Validators.compose([Validators.required])],
    specialisation: [null, Validators.compose([])],
    completion: [null, Validators.compose([])],

    education_file_image: [null, Validators.compose([])],
    education_file_image_file: [null, Validators.compose([])],
  });
  this.employeeAccidentForm = formBuilder.group({
    accident_type: [null, Validators.compose([Validators.required])],
    accident_name: [null, Validators.compose([])],
    description: [null, Validators.compose([])],
    comments: [null, Validators.compose([])],

    accident_file_image: [null, Validators.compose([])],
    accident_file_image_file: [null, Validators.compose([])],
  });
  this.employeeTrainingForm = formBuilder.group({
    training_type: [null, Validators.compose([Validators.required])],
    training_name: [null, Validators.compose([])],
    description: [null, Validators.compose([])],
    comments: [null, Validators.compose([])],

    training_file_image: [null, Validators.compose([])],
    training_file_image_file: [null, Validators.compose([])],
  });
  this.employeeExtraCurriculumForm = formBuilder.group({
    extra_curricular_type: [null, Validators.compose([Validators.required])],
    extracurriculum_name: [null, Validators.compose([])],
    description: [null, Validators.compose([])],
    comments: [null, Validators.compose([])],

    extracurriculum_file_image: [null, Validators.compose([])],
    extracurriculum_file_image_file: [null, Validators.compose([])],
  });


  
  this.yesNoMaster = [
    { value: 'yes', description: 'Yes' },
    { value: 'no', description: 'No' },
  ];
  this.addressProofMaster = [
    { value: 'pan', description: 'Pancard' },
    { value: 'aadhaar', description: 'Aadhaar Card' },
  ];
  this.accountTypeMaster = [
    { value: 'saving', description: 'Savings Account' },
    { value: 'current', description: 'Current Account' },
  ];

  this.employeeAddressForm
    .get('diff_current_add')
    ?.valueChanges.subscribe((val) => {
      if (val == 'yes') {
        // $('#current-address-fields').show(500);
      } else {
        // $('#current-address-fields').hide(500);
      }
    });
  this.fetchStates();

  empService
    .ValidInvitationLink({
      invitation_id: this.invitation_id,
    }).then((res) => {
      if (res.doc) {
        this.isLinkValid = true;
        this.employee_details = res.doc.employee_details;
        if (res.doc.employee_details) {
          this.fetchEmployeeDetails();
        }
        this.isLoading = false;
      }
      this.isLoading = false;
    });
}
ngOnInit(): void {
  this.currentLanguage$ = this.langService.currentLanguage$.subscribe(async () => {
    this.lang = this.langService.getLangLocalStorage();
        return new Promise<boolean>(async (resolve, reject) => {
            await this.langService.loadTranslations('invite-employee', 'InviteEmployeeComponent')?.then((res: any) => {
                this.translations = res;
                resolve(true);
            }).catch((err: any) => {
                console.error(`Failed to load translations`, err);
                reject(false);
            });
        })
    });
}
async createEmployee(e: any) {
  try {
    this.employeeDetailsForm.markAllAsTouched();
    setTimeout(function () {
      Global.scrollToQuery('p.error-element');
    }, 100);

    if (this.employeeDetailsForm.valid) {
      e.target.classList.add('btn-loading');

      let res = await this.empService
        .createEmployeePublic({
                 invitation_id: this.invitation_id,
                // "emp_hod": this.employeeDetailsForm?.value?.emp_hod?.id ?? "",
                "gross_salary": this.employeeDetailsForm?.value?.gross_salary ?? "",
                "emp_first_name": this.employeeDetailsForm?.value?.emp_first_name ?? "",
                "emp_first_name_uae": this.employeeDetailsForm?.value?.emp_first_name_uae ?? "",
                "emp_last_name": this.employeeDetailsForm?.value?.emp_last_name ?? "",
                "emp_last_name_uae": this.employeeDetailsForm?.value?.emp_last_name_uae ?? "",
                "emp_father_name": this.employeeDetailsForm?.value?.emp_father_name ?? "",
                "emp_mother_name": this.employeeDetailsForm?.value?.emp_mother_name ?? "",
                "official_email_id": this.employeeDetailsForm?.value?.official_email_id ?? "",
                "email_id": this.employeeDetailsForm?.value?.email_id ?? "",
                "mobile_no": this.employeeDetailsForm?.value?.mobile_no ?? "",
                "official_mobile_no": this.employeeDetailsForm?.value?.official_mobile_no ?? "",
                "emirates_id": this.employeeDetailsForm?.value?.emirates_id ?? "",
                "emirates_image": this.employeeDetailsForm?.value?.emirates_image_file ?? "",
                "emirates_valid_to": this.employeeDetailsForm?.value?.emirates_valid_to ?? "",
                "linked_uae_pass": this.employeeDetailsForm?.value?.linked_uae_pass ?? "",
                "uae_pass_valid_to": this.employeeDetailsForm?.value?.uae_pass,
                "alternate_mob_no": this.employeeDetailsForm?.value?.alternate_mob_no ?? "",
                "emp_dob": this.employeeDetailsForm?.value?.emp_dob ?? "",
                "sex": this.employeeDetailsForm?.value?.sex?.value ?? "",
                "passport_no": this.employeeDetailsForm?.value?.passport_no ?? "",
                "emp_passport_image": this.employeeDetailsForm?.value?.emp_passport_image_file ?? "",
                "passport_val_form": this.employeeDetailsForm?.value?.passport_val_form ?? "",
                "passport_val_to": this.employeeDetailsForm?.value?.passport_val_to ?? "",
                "nationality": this.employeeDetailsForm?.value?.nationality ?? "",
                "blood_group": this.employeeDetailsForm?.value?.blood_group?.value ?? "",
                "physical_disability": this.employeeDetailsForm?.value?.physical_disability?.value ?? "",
                "marital_status": this.employeeDetailsForm?.value?.marital_status?.value ?? "",
                "marriage_date": this.employeeDetailsForm?.value?.marriage_date ?? "",
                "emergency_contact_no": this.employeeDetailsForm?.value?.emergency_contact_no ?? "",
                "emergency_contact_name": this.employeeDetailsForm?.value?.emergency_contact_name ?? "",
                "domicile": this.employeeDetailsForm?.value?.domicile ?? "",
                "height": this.employeeDetailsForm?.value?.height ?? "",
                "religion": this.employeeDetailsForm?.value?.religion?.value ?? "",
                "additional_id_image": this.employeeDetailsForm?.value?.additional_id_image_source ?? "",
                "profile_image": this.employeeDetailsForm?.value?.profile_image_file ?? "",
        });
      if (res.status !== 'success') throw res;
      this.employee_details = res.user_data;
      this.toastr.success(res.message);
      e.target.classList.remove('btn-loading');
    }
  } catch (err: any) {
    {
      e.target.classList.remove('btn-loading');
      if (err.status == 'val_err') {
        this.toastr.error(Global.showValidationMessage(err.val_msg));
      } else {
        this.toastr.error(err);
        this.toastr.error(err.message);
      }
    }
  }
}

async updateEmployee(e: any) {
  try {
    this.employeeDetailsForm.markAllAsTouched();
    setTimeout(function () {
      Global.scrollToQuery('p.error-element');
    }, 100);

    if (this.employeeDetailsForm.valid) {
      e.target.classList.add('btn-loading');

      let res = await this.empService
        .updateEmployeePublic({
          employee_id: this.employee_details?._id,
          invitation_id: this.invitation_id,
         "emp_hod": this.employeeDetailsForm?.value?.emp_hod?.id ?? "",
            "gross_salary": this.employeeDetailsForm?.value?.gross_salary ?? "",
            "emp_first_name": this.employeeDetailsForm?.value?.emp_first_name ?? "",
            "emp_first_name_uae": this.employeeDetailsForm?.value?.emp_first_name_uae ?? "",
            "emp_last_name": this.employeeDetailsForm?.value?.emp_last_name ?? "",
            "emp_last_name_uae": this.employeeDetailsForm?.value?.emp_last_name_uae ?? "",
            "emp_father_name": this.employeeDetailsForm?.value?.emp_father_name ?? "",
            "emp_mother_name": this.employeeDetailsForm?.value?.emp_mother_name ?? "",
            "official_email_id": this.employeeDetailsForm?.value?.official_email_id ?? "",
            "email_id": this.employeeDetailsForm?.value?.email_id ?? "",
            "mobile_no": this.employeeDetailsForm?.value?.mobile_no ?? "",
            "official_mobile_no": this.employeeDetailsForm?.value?.mobile_no ?? "",
            "emirates_id": this.employeeDetailsForm?.value?.emirates_id ?? "",
            "emirates_image": this.employeeDetailsForm?.value?.emirates_image_file ?? "",
            "emirates_valid_to": this.employeeDetailsForm?.value?.emirates_valid_to ?? "",
            "linked_uae_pass": this.employeeDetailsForm?.value?.linked_uae_pass ?? "",
            "uae_pass_valid_to": this.employeeDetailsForm?.value?.uae_pass_valid_to ?? "",
            "alternate_mob_no": this.employeeDetailsForm?.value?.alternate_mob_no ?? "",
            "emp_dob": this.employeeDetailsForm?.value?.emp_dob ?? "",
            "sex": this.employeeDetailsForm?.value?.sex?.value ?? "",
            "passport_no": this.employeeDetailsForm?.value?.passport_no ?? "",
            "emp_passport_image": this.employeeDetailsForm?.value?.emp_passport_image_file ?? "",
            "passport_val_form": this.employeeDetailsForm?.value?.passport_val_form ?? "",
            "passport_val_to": this.employeeDetailsForm?.value?.passport_val_to ?? "",
            "nationality": this.employeeDetailsForm?.value?.nationality ?? "",
            "blood_group": this.employeeDetailsForm?.value?.blood_group?.value ?? "",
            "physical_disability": this.employeeDetailsForm?.value?.physical_disability?.value ?? "",
            "marital_status": this.employeeDetailsForm?.value?.marital_status?.value ?? "",
            "marriage_date": this.employeeDetailsForm?.value?.marriage_date ?? "",
            "emergency_contact_no": this.employeeDetailsForm?.value?.emergency_contact_no ?? "",
            "emergency_contact_name": this.employeeDetailsForm?.value?.emergency_contact_name ?? "",
            "domicile": this.employeeDetailsForm?.value?.domicile ?? "",
            "height": this.employeeDetailsForm?.value?.height ?? "",
            "religion": this.employeeDetailsForm?.value?.religion?.value ?? "",
            "additional_id_image": this.employeeDetailsForm?.value?.additional_id_image_source ?? "",
            "profile_image": this.employeeDetailsForm?.value?.profile_image_file ?? "",
        });
      if (res.status !== 'success') throw res;
      // this.employee_details = res.user_data;
      this.fetchEmployeeDetails();
      // this.formType = 'address_details';
      this.toastr.success(res.message);

      e.target.classList.remove('btn-loading');
    }
  } catch (err: any) {
    {
      e.target.classList.remove('btn-loading');
      if (err.status == 'val_err') {
        this.toastr.error(Global.showValidationMessage(err.val_msg));
      } else {
        this.toastr.error(err);
        this.toastr.error(err.message);
      }
    }
  }
}

async updateEmployeeAddress(e: any) {
  try {
    if (this.employeeAddressForm.valid) {
      e.target.classList.add('btn-loading');

      let res = await this.empService
        .updateEmployeeAddressPublic({
         "resident_no": this.employeeAddressForm.value?.resident_no ?? '',
          "residential_name":
            this.employeeAddressForm.value?.residential_name ?? '',
          "road": this.employeeAddressForm.value?.road ?? '',
          "locality": this.employeeAddressForm.value?.locality ?? '',
          "city": this.employeeAddressForm.value?.city ?? '',
          "district": this.employeeAddressForm.value?.district ?? '',
          "state": this.employeeAddressForm.value?.state?.description ?? '',
          "pincode": this.employeeAddressForm.value?.pincode ?? '',
          "country": this.employeeAddressForm.value?.country ?? '',
          "diff_current_add":
            this.employeeAddressForm.value?.diff_current_add ?? '',
          curr_resident_no:
            this.employeeAddressForm.value?.diff_current_add == 'yes'
              ? this.employeeAddressForm.value?.curr_resident_no ?? ''
              : '',
          "curr_residential_name":
            this.employeeAddressForm.value?.diff_current_add == 'yes'
              ? this.employeeAddressForm.value?.curr_residential_name ?? ''
              : '',
          "curr_road":
            this.employeeAddressForm.value?.diff_current_add == 'yes'
              ? this.employeeAddressForm.value?.curr_road ?? ''
              : '',
          "curr_locality":
            this.employeeAddressForm.value?.diff_current_add == 'yes'
              ? this.employeeAddressForm.value?.curr_locality ?? ''
              : '',
          "curr_city":
            this.employeeAddressForm.value?.diff_current_add == 'yes'
              ? this.employeeAddressForm.value?.curr_city ?? ''
              : '',
          "curr_district":
            this.employeeAddressForm.value?.diff_current_add == 'yes'
              ? this.employeeAddressForm.value?.curr_district ?? ''
              : '',
          "curr_state":
            this.employeeAddressForm.value?.diff_current_add == 'yes'
              ? this.employeeAddressForm.value?.state?.description ?? ''
              : '',
          "curr_pincode":
            this.employeeAddressForm.value?.diff_current_add == 'yes'
              ? this.employeeAddressForm.value?.curr_pincode ?? ''
              : '',
          "curr_country":
            this.employeeAddressForm.value?.diff_current_add == 'yes'
              ? this.employeeAddressForm.value?.curr_country ?? ''
              : '',
          // "employee_id": this.employee_id,
          employee_id: this.employee_details._id,
        });

      if (res.status !== 'success') throw res;
      this.address_details = res.emp_det;
      // this.formType = 'bank_details';
      this.toastr.success(res.message);

      e.target.classList.remove('btn-loading');
    }
  } catch (err: any) {
    {
      e.target.classList.remove('btn-loading');
        this.toastr.error(err);
        this.toastr.error(err.message);
    }
  }
}

async updateEmployeeBankDetail(e: any) {
  try {
    this.employeeBankForm.markAllAsTouched();
    Global.scrollToQuery('p.error-element');

    if (this.employeeBankForm.valid) {
      if (
        this.employeeBankForm.value.account_no &&
        this.employeeBankForm.value.account_no !==
          this.employeeBankForm.value.account_no_confirmation
      ) {
        this.toastr.error("Account number confirmation does't matched");
        return;
      }

      e.target.classList.add('btn-loading');

      let res = await this.empService
        .updateEmployeeBankDetailsPublic({
               'name_on_passbook': this.employeeBankForm?.value?.name_on_passbook ?? "",
                'bank_name': this.employeeBankForm?.value?.bank_name ?? "",
                'branch_name': this.employeeBankForm?.value?.branch_name ?? "",
                'branch_address': this.employeeBankForm?.value?.branch_address ?? "",
                'branch_code': this.employeeBankForm?.value?.branch_code ?? "",
                // 'branch_pin': this.employeeBankForm?.value?.branch_pin ?? "",
                'account_no': this.employeeBankForm?.value?.account_no ?? "",
                'account_type': this.employeeBankForm?.value?.account_type?.value ?? "",
                // 'ifsc_code': this.employeeBankForm?.value?.ifsc_code ?? "",
                'routing_no': this.employeeBankForm?.value?.routing_no ?? "",
                'iban': this.employeeBankForm?.value?.iban ?? "",
                'swift_code': this.employeeBankForm?.value?.swift_code ?? "",
                'micr_no': this.employeeBankForm?.value?.micr_no ?? "",
                'cancel_cheque': this.employeeBankForm?.value?.cancel_cheque_file ?? "",
                // 'employee_id': this.employee_id
                 employee_id: this.employee_details._id,
        });
      if (res.status !== 'success') throw res;
      this.bank_details = res.emp_det;
      // this.formType = 'pf_esi_data';
      this.toastr.success(res.message);

      e.target.classList.remove('btn-loading');
    }
  } catch (err: any) {
    {
      e.target.classList.remove('btn-loading');
      if (err.status == 'val_err') {
        this.toastr.error(Global.showValidationMessage(err.val_msg));
      } else {
        this.toastr.error(err);
        this.toastr.error(err.message);
      }
    }
  }
}


updateEmployeeEducationDetails(event: any) {
  this.employeeEducationForm.markAllAsTouched();
  if(this.employeeEducationForm.invalid) return;
  Global.scrollToQuery('p.error-element');

  if (this.employeeEducationForm.valid) {
    event.target.classList.add('btn-loading');

    this.empService
      .updateEmployeeEducationDetailsPublic({
        institute: this.employeeEducationForm.value.institute ?? '',
        university: this.employeeEducationForm.value.university ?? '',
        stream: this.employeeEducationForm.value.stream ?? '',
        level: this.employeeEducationForm.value.level ?? '',
        specialisation: this.employeeEducationForm.value.specialisation ?? '',
        completion: this.employeeEducationForm.value.completion ?? '',
        education_file_image:
          this.employeeEducationForm.value.education_file_image_file ?? '',
        employee_id: this.employee_details._id,
        education_id: this.education_id,
      })
      .then(
        (res) => {
          if (res.status == 'success') {
            this.toastr.success(res.message);
            this.fetchEmployeeDetails();
            if (!this.education_id) {
              this.cancelEntry();
            }
          }  
            this.toastr.error(res.message);
          

          event.target.classList.remove('btn-loading');
        },
        (err) => {
          event.target.classList.remove('btn-loading');
          this.toastr.error(err);
        }
      );
  }
}

updateEmployeeAccidentDetails(event: any) {
  this.employeeAccidentForm.markAllAsTouched();
  if(this.employeeAccidentForm.invalid) return
  Global.scrollToQuery('p.error-element');

  if (this.employeeAccidentForm.valid) {
    event.target.classList.add('btn-loading');

    this.empService
      .updateEmployeeAccidentDetailsPublic({
        accident_type: this.employeeAccidentForm.value.accident_type ?? '',
        accident_name: this.employeeAccidentForm.value.accident_name ?? '',
        description: this.employeeAccidentForm.value.description ?? '',
        comments: this.employeeAccidentForm.value.comments ?? '',
        accident_file_image:
          this.employeeAccidentForm.value.accident_file_image_file ?? '',
        employee_id: this.employee_details?._id,
        accident_id: this.accident_id,
      })
      .then(
        (res) => {
          if (res.status == 'success') {
            this.toastr.success(res.message);
            this.fetchEmployeeDetails();

            if (!this.accident_id) {
              this.cancelEntry();
            }
          } else if (res.status == 'val_err') {
            this.toastr.error(Global.showValidationMessage(res.val_msg));
          } else {
            this.toastr.error(res.message);
          }

          event.target.classList.remove('btn-loading');
        },
        (err) => {
          event.target.classList.remove('btn-loading');
          this.toastr.error(err);
        }
      );
  }
}

updateEmployeeTrainingDetails(event: any) {
  this.employeeTrainingForm.markAllAsTouched();
  if(this.employeeTrainingForm.invalid) return
  Global.scrollToQuery('p.error-element');

  if (this.employeeTrainingForm.valid) {
    event.target.classList.add('btn-loading');

    this.empService
      .updateEmployeeTrainingDetailsPublic({
        training_type: this.employeeTrainingForm.value.training_type ?? '',
        training_name: this.employeeTrainingForm.value.training_name ?? '',
        description: this.employeeTrainingForm.value.description ?? '',
        comments: this.employeeTrainingForm.value.comments ?? '',
        training_file_image:
          this.employeeTrainingForm.value.training_file_image_file ?? '',
        employee_id: this.employee_details?._id,
        training_id: this.training_id,
      })
      .then(
        (res) => {
          if (res.status == 'success') {
            this.toastr.success(res.message);
            this.fetchEmployeeDetails();

            if (!this.accident_id) {
              this.cancelEntry();
            }
          } else if (res.status == 'val_err') {
            this.toastr.error(Global.showValidationMessage(res.val_msg));
          } else {
            this.toastr.error(res.message);
          }

          event.target.classList.remove('btn-loading');
        },
        (err) => {
          event.target.classList.remove('btn-loading');
          this.toastr.error(err);
        }
      );
  }
}

updateEmployeeExtraCurriculumDetails(event: any) {
  this.employeeExtraCurriculumForm.markAllAsTouched();
  if(this.employeeExtraCurriculumForm.invalid) return 
  Global.scrollToQuery('p.error-element');

  if (this.employeeExtraCurriculumForm.valid) {
    event.target.classList.add('btn-loading');

    this.empService
      .updateEmployeeExtraCurriculumDetailsPublic({
        extra_curricular_type:
          this.employeeExtraCurriculumForm.value.extra_curricular_type ?? '',
        extracurriculum_name:
          this.employeeExtraCurriculumForm.value.extracurriculum_name ?? '',
        description: this.employeeExtraCurriculumForm.value.description ?? '',
        comments: this.employeeExtraCurriculumForm.value.comments ?? '',
        extracurriculum_file_image:
          this.employeeExtraCurriculumForm.value
            .extracurriculum_file_image_file ?? '',
        employee_id: this.employee_details?._id,
        extracurriculum_id: this.extracurriculum_id,
      })
      .then(
        (res) => {
          if (res.status == 'success') {
            this.toastr.success(res.message);
            this.fetchEmployeeDetails();

            if (!this.extracurriculum_id) {
              this.cancelEntry();
            }
          } else if (res.status == 'val_err') {
            this.toastr.error(Global.showValidationMessage(res.val_msg));
          } else {
            this.toastr.error(res.message);
          }

          event.target.classList.remove('btn-loading');
        },
        (err) => {
          event.target.classList.remove('btn-loading');
          this.toastr.error(err);
        }
      );
  }
}

cancelEntry() {
  Global.resetForm(this.employeeDetailsForm);
  this.employeeDetailsForm.reset();
  this.employeeAddressForm.reset();
  this.employeeBankForm.reset();
  this.employeeEducationForm.reset();
  this.employeeAccidentForm.reset();
  this.employeeTrainingForm.reset();
  this.employeeExtraCurriculumForm.reset();
  this.fetchEmployeeDetails();
}

onFileChanged(
  event: any,
  formGroup: FormGroup,
  file: any,
  target: any
) {
  if (event.target.files.length > 0) {
    const file = event.target.files[0];
    formGroup.patchValue({
      [target]: file,
    });
  }
}
stateMaster!: any[];

fetchStates() {
  this.spinner.show();

  this.empService.fetchStatesPublic().then(
    (res) => {
      if (res.status == 'success') {
        this.stateMaster = [];
        for (const key in res.state_list[0].states) {
          if (
            Object.prototype.hasOwnProperty.call(
              res.state_list[0].states,
              key
            )
          ) {
            const element = res.state_list[0].states[key];
            this.stateMaster.push({
              id: element.id,
              description: element.name,
            });
          }
        }
      } else {
        this.toastr.error(res.message);
      }

      this.spinner.hide();
    },
    (err) => {
      this.toastr.error(err);
      this.spinner.hide();
    }
  );
}

getEmployeeMaster() {
  this.spinner.show();
  this.empService.fetchDispensaries({}).then(
    (res: any) => {
      if (res.status == 'success') {
        this.dispensaryMaster = [];
        res.dispensary.docs.forEach((element: any) => {
          this.dispensaryMaster.push({
            id: element._id,
            description: element.dispensary_name,
          });
        });
      } else {
        this.toastr.error(res.message);
      }

      this.spinner.hide();
    },
    (err) => {
      this.spinner.hide();
      this.toastr.error(err);
    }
  );
}

fetchEmployeeDetails() {
  this.spinner.show();
  this.empService
    .getEmployeeDetailsPublic({ employee_id: this.employee_details._id })
    .then(
      (res: any) => {
        if (res.status == 'success') {
          this.employee_details = res.employee_det;
          let emp_data = res.employee_det.emp_det;

          this.updateFormValues(
            this.employeeDetailsForm,
            this.employee_details
          );
          this.updateFormValues(this.employeeAddressForm, {
            ...emp_data.emp_address,
            ...emp_data.emp_curr_address,
          });
          this.updateFormValues(this.employeeBankForm, emp_data.bank_details);
          this.updateFormValues(
            this.employmentForm,
            emp_data.pf_esic_details
          );

          (emp_data.pf_esic_details?.esic_family_details ?? []).forEach(
            (details: any) => {
              this.addFormRows(
                this.employmentForm,
                'esic_family_details',
                details
              );
            }
          );

          (emp_data.pf_esic_details?.pf_nominee_details ?? []).forEach(
            (details: any) => {
              this.addFormRows(
                this.employmentForm,
                'pf_nominee_details',
                details
              );
            }
          );
        } else {
          this.toastr.error(res.message);
        }

        this.spinner.hide();
      },
      (err: any) => {
        this.spinner.hide();
        this.toastr.error(err);
      }
    );
}

updateFormValues(group: FormGroup | FormArray, values: any) {
  try {
    Object.keys(group.controls).forEach((k) => {
      const control = group.get(k);
      if (values && values[k] !== undefined) {
        if (!Array.isArray(values[k])) {
          control?.setValue(values[k]);
          if (k == 'sex') {
            control?.setValue(
              this.sexMaster.find((m) => m.value == values[k])
            );
          }
          if (k == 'emp_dob') {
            control?.setValue(moment(values).format('YYYY-MM-DD'));
          }
          if (k == 'religion') {
            control?.setValue(
              this.religionMaster.find((m) => m.value == values[k])
            );
          }
        }
      }
    });
  } catch (err) {
    throw err;
  }
}

createDispensary(event: any) {
  if (this.dispensaryForm.valid) {
    event.target.classList.add('btn-loading');

    this.empService
      .createDispensary({
        dispensary_name: this.dispensaryForm.value.dispensary_name,
        status: 'active',
      })
      .then(
        (res) => {
          if (res.status == 'success') {
            this.toastr.success(res.message);
            this.dispensaryForm.reset();
            this.getEmployeeMaster();
          } else {
            this.toastr.error(res.message);
          }

          event.target.classList.remove('btn-loading');
        },
        (err) => {
          event.target.classList.remove('btn-loading');
          this.toastr.error(err);
        }
      );
  }
}

shuffleFields() {
  // setTimeout(() => {
  //   if ($('#previousemployment-toggle-ab')?.hasClass('on')) {
  //     $('#prevemployment-fields-ab')?.show(300);
  //   } else {
  //     $('#prevemployment-fields-ab')?.hide(300);
  //   }
  // }, 100);
}

initFormRows(type: any, data: any = null) {
  switch (type) {
    case 'esic_family_details':
      return this.formBuilder.group({
        fm_name: [data?.fm_name ?? null, Validators.compose([])],
        fm_dob: [data?.fm_dob ?? null, Validators.compose([])],
        fm_relation: [
          data?.fm_relation
            ? this.relationMaster?.find((obj: any) => {
                return obj.value == data?.fm_relation;
              }) ?? null
            : null,
          Validators.compose([]),
        ],
        sex: [
          data?.sex
            ? this.sexMaster?.find((obj: any) => {
                return obj.value == data?.sex;
              }) ?? null
            : null,
          Validators.compose([]),
        ],
        residing_with_if: [
          data?.residing_with_if == 'yes' ? true : false,
          Validators.compose([]),
        ],
      });

    case 'pf_nominee_details':
      return this.formBuilder.group({
        aadhar_no: [
          data?.aadhar_no ?? null,
          Validators.compose([
            Validators.pattern('^[0-9]*$'),
            Validators.minLength(12),
            Validators.maxLength(12),
          ]),
        ],
        dob: [data?.dob ?? null, Validators.compose([])],
        name: [data?.name ?? null, Validators.compose([])],
        sex: [
          data?.sex
            ? this.sexMaster?.find((obj: any) => {
                return obj.value == data?.sex;
              }) ?? null
            : null,
          Validators.compose([]),
        ],
        fm_relation: [
          data?.fm_relation
            ? this.relationMaster?.find((obj: any) => {
                return obj.value == data?.fm_relation;
              }) ?? null
            : null,
          Validators.compose([]),
        ],
        address: [data?.address ?? null, Validators.compose([])],
        profile_pic: [null, Validators.compose([])],
        profile_pic_file: [null, Validators.compose([])],
      });

    default:
      return this.formBuilder.group({});
  }
}

addFormRows(formGroup: FormGroup, type: any, data: any = null) {
  const control = <FormArray>formGroup.get(type);
  switch (type) {
    case 'esic_family_details':
      control.push(this.initFormRows('esic_family_details', data));
      break;

    case 'pf_nominee_details':
      control.push(this.initFormRows('pf_nominee_details', data));
      break;
  }
}

}
